import React from "react";
import Joi from "joi-browser";
import Form from "../common/form";
import { toast } from "react-toastify";
import { getTags } from "../../services/tagService";
import { getAttachment, saveAttachment } from "../../services/attachmentService";
import copy from 'copy-to-clipboard';


class AttachmentsForm extends Form {
  state = {
    data: {
      name: "",
      tags: [],
      file: null
    },
    tags: [],
    errors: {},
  };

  schema = {
    _id: Joi.string(),
    name: Joi.string().required().label("Nombre"),
    tags: Joi.array().label("Los tags"),
    file: Joi.any().label("El archivo"),
  };

  async populateAttachments() {
    try {
      const attachmentId = this.props.match.params.id;
      if (attachmentId === "new") return;

      const res = await getAttachment(attachmentId);
      const attachment = res.data.attachment;

      this.setState({ data: this.mapToViewModel(attachment) });
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        this.props.history.replace("/not-found");
    }
  }

  async populateTags() {
    const { data } = await getTags();
    this.setState({ tags: data.tags });
  }

  async componentDidMount() {
    await this.populateTags()
    await this.populateAttachments()
  }

  mapToViewModel(attachment) {
    return {
      _id: attachment._id,
      name: attachment.name,
      tags: attachment.tags
    };
  }


  doSubmit = async () => {

    const formData = new FormData() 
    if(this.state.data.file)
      formData.append('file', this.state.data.file)
  
    try {
      const {data} = this.state
      const tags = data.tags.map((tag) => tag._id)
      formData.append('name', this.state.data.name)
      formData.append('tags', JSON.stringify(tags))
      await saveAttachment(formData, data._id);

      this.props.history.push("/attachments");

    }catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data);
      }
    }
  };


  handleChangeMultiple = ({ currentTarget: input }) => {
    const exists = this.state.data.tags.find(
      (tag) => tag._id === input.value
    );
    if (typeof exists !== "undefined") return;

    const tag = this.state.tags.filter(
      (tag) => tag._id === input.value
    );
    const data = { ...this.state.data };
    const originalTags = data[input.name];

    const newTags = originalTags.concat(tag);
    data[input.name] = newTags;
    this.setState({ data });
  };

  handleClickBadge = (tagId) => {
    const newTags = this.state.data.tags.filter(
      (cat) => cat._id !== tagId
    );
    const data = { ...this.state.data };
    data.tags = newTags;
    this.setState({ data });
  };

  onChangeHandler = (event) => {
    const { data } = this.state;
    data.file = event.target.files[0];
    this.setState({ data });
  };

  setClipboard = () => {

    copy(this.state.data.location);

  }




  render() {
    const mailingId = this.props.match.params.id;
    return (
      <div>
        {mailingId === "new" && <h2>Crear Adjunto</h2>}
        {mailingId !== "new" && <h2>Editar Adjunto</h2>}
        <form onSubmit={this.handleSubmit}>
          {this.renderInput("name", "Nombre")}
          {/* <label style={{ marginTop: "10px" }}>Adjunto</label> */}
          <input name="attachment" type="file" onChange={this.onChangeHandler} /> <br />
           {/* <a target="_blank" href={this.state.data.location}><button className="btn btn-default">Ver</button></a> 
           <button type={"button"} onClick={this.setClipboard} className="btn btn-outline-primary" style={{ marginLeft: "10px" }} >Copiar link</button>  */}
          {this.state.tags.length > 0 && (
            <div className="form-group">
              <label>Tags</label>
              <select
                name="tags"
                onChange={this.handleChangeMultiple}
                className="form-control"
                multiple
              >
                <option value="" />
                {this.state.tags.map((option) => (
                  <option key={option._id} value={option._id}>
                    {option.name}
                  </option>
                ))}
              </select>
              <div className="mt-2">
                {this.state.data.tags.map((tag) => (
                  <p
                    onClick={() => this.handleClickBadge(tag._id)}
                    className="badge badge-secondary p-2 mr-2"
                    key={`badge-${tag._id}`}
                  >
                    {tag.name} X
                  </p>
                ))}
              </div>
            </div>
          )}
         {this.renderButton("Save")}
        </form>
      </div>
    );
  }
}

export default AttachmentsForm;
