import React from "react";

const Checkbox = ({ name, label, error, type, value /*...rest*/ }) => {
  return (
    <div className="form-group">
      <label htmlFor={name}>{label}</label>
      {value ==='true' && <input defaultChecked type={type} value={value} name={name} id={name} className="form-control" />}
  {value !=='true' && <input type={type} value={value} name={name} id={name} className="form-control" />} 
      {error && <div className="alert alert-danger">{error}</div>}
    </div>
  );
};

export default Checkbox;
