import http from "./httpService";
import { apiUrl } from "../config.json";

const apiEndpoint = apiUrl + "/mailing-numbers";


function mailingNumberUrl(id) {
    return `${apiEndpoint}/${id}`;
  }

export function getMailingNumbers() {
  return http.get(`${apiEndpoint}`);
}

export async function downloadMailingNumber(mailingNumber) {
  const response = await http.get(`${apiEndpoint}/download/${mailingNumber._id}`);
  // console.log(response.data.html)
  const blob = new Blob([response.data.html]);
  let url = window.URL.createObjectURL(blob);
  let a = document.createElement("a");
  a.href = url;
  a.download = `${mailingNumber.number}.html`;
  a.click();
}

export function getMailingNumbersByMailingId(mailingId) {
  return http.get(`${apiEndpoint}/by-mailing/${mailingId}`);
}

export function getMailingNumbersByProgramId(programId) {
  return http.get(`${apiEndpoint}/by-program/${programId}`);
}


export function getMailingNumber(id) {
  return http.get(mailingNumberUrl(id));
}

export function saveMailingNumber(mailingNumber) {
  
    const body = { ...mailingNumber };
    body.html = JSON.stringify(mailingNumber.html)
    delete body.programId;
    delete body.templateId;

    const tags = body.tags.map((tag) => tag._id)
    body.tags = tags

  if (mailingNumber._id) {
    delete body._id;
    return http.put(mailingNumberUrl(mailingNumber._id), body);
  }

  return http.post(`${apiEndpoint}`, body);
}

export function copyMailingNumber(mailingNumber){
  return http.post(`${apiEndpoint}/copy`, mailingNumber)
}

export function sendTest(body){
  return http.post(`${apiEndpoint}/send-test`, body)
}

export function deleteMailingNumber(id) {
  return http.delete(mailingNumberUrl(id));
}
