import React, { Component } from "react";
import auth from "../../services/authService";
import { Link } from "react-router-dom";
import Table from "../common/table";

class AdminsTable extends Component {
  columns = [
    {
      path: "name",
      label: "Nombre",
      content: admin => <Link to={`/admins/${admin._id}`}>{admin.name}</Link>
    },
    { path: "lastname", label: "Apellido" },
    { path: "email", label: "Email" }
  ];

  deleteColumn = {
    key: "delete",
    content: admin => (
      <button
        onClick={() => this.props.onDelete(admin)}
        className="btn btn-danger btn-sm"
      >
        Delete
      </button>
    )
  };

  constructor() {
    super();
    const user = auth.getCurrentUser();
    if (user && user.isAdmin) this.columns.push(this.deleteColumn);
  }

  render() {
    const { admins, onSort, sortColumn } = this.props;

    return (
      <Table
        columns={this.columns}
        data={admins}
        sortColumn={sortColumn}
        onSort={onSort}
      />
    );
  }
}

export default AdminsTable;
