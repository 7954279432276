import http from "./httpService";
import { apiUrl } from "../config.json";

const apiEndpoint = apiUrl + "/user-categories";


function categoryUrl(id) {
    return `${apiEndpoint}/${id}`;
  }

export function getCategories() {
  return http.get(`${apiEndpoint}`);
}

export function getCategoriesByProgramId(programId) {
  return http.get(`${apiEndpoint}/by-program/${programId}`);
}

export function getCategory(id) {
  return http.get(categoryUrl(id));
}

export function saveCategory(category) {
  if (category._id) {
    const body = { ...category };
    delete body._id;
    return http.put(categoryUrl(category._id), body);
  }

  return http.post(`${apiEndpoint}`, category);
}

export function deleteCategory(id) {
  return http.delete(categoryUrl(id));
}
