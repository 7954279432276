import React, { Component } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import MailingNumbersTable from "./table";
import Pagination from "../common/pagination";
import {
  getPieces,
  copyPiece,
  downloadPiece,
  deletePiece,
} from "../../services/pieceService";
import { getTags } from "../../services/tagService";
import { paginate } from "../../utils/paginate";
import _ from "lodash";
import SearchBox from "../searchBox";
// import "./styles.css"

class Pieces extends Component {
  state = {
    mailingNumbers: [],
    pieces: [],
    programs: [],
    tags: [],
    currentPage: 1,
    pageSize: 4,
    searchQuery: "",
    selectedProgram: null,
    selectedMailing: null,
    selectedTag: null,
    sortColumn: { path: "name", order: "asc" },
    itemsCount: 1,
    loading: true,
  };

  async componentDidMount() {
    const { data } = await getPieces(
      this.state.currentPage,
      this.state.searchQuery,
      this.state.pageSize
    );

    const { data: tagsData } = await getTags();
    const tags = [{ _id: "", name: "Todos los Tags" }, ...tagsData.tags];

    this.setState({
      pieces: data.pieces,
      tags,
      itemsCount: data.count,
      loading: false,
    });
  }

  handleDelete = async (piece) => {
    const originalPieces = this.state.pieces;
    const pieces = originalPieces.filter((m) => m._id !== piece._id);
    this.setState({ pieces });

    try {
      await deletePiece(piece._id);
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        toast.error("This piece has already been deleted.");

      this.setState({ pieces: originalPieces });
    }
  };

  handleDownload = async (piece) => {
    await downloadPiece(piece);
  };

  handlePageChange = async (page) => {
    this.setState({ loading: true });
    const { data } = await getPieces(
      this.state.currentPage + 1,
      this.state.searchQuery,
      this.state.pageSize
    );
    this.setState({
      currentPage: page,
      pieces: data.pieces,
      loading: false,
      itemsCount: data.count,
    });
  };

  handleMailingSelect = async (piece) => {
    this.setState({
      selectedMailing: piece,
      searchQuery: "",
      currentPage: 1,
    });
  };

  handleTagSelect = async (tag) => {
    this.setState({
      selectedTag: tag,
      searchQuery: "",
      currentPage: 1,
    });
  };

  handleSearch = async (query) => {
    this.setState({ searchQuery: query });
    if (query.length < 4) return;
    this.setState({ currentPage: 1, loading: true });
    const { data } = await getPieces(
      this.state.currentPage,
      this.state.searchQuery,
      this.state.pageSize
    );
    this.setState({
      pieces: data.pieces,
      loading: false,
      itemsCount: data.itemsCount,
    });
  };

  handleSort = (sortColumn) => {
    this.setState({ sortColumn });
  };

  handleCopy = async (piece) => {
    const res = await copyPiece(piece);
    let allPieces = this.state.pieces;
    allPieces.push(res.data.piece);
    this.setState({ pieces: allPieces });
  };

  render() {
    const { length: count } = this.state.pieces;
    const {
      loading,
      pageSize,
      currentPage,
      sortColumn,
      searchQuery,
      pieces,
      itemsCount,
    } = this.state;

    return (
      <div className="row">
        <div className="col">
          <Link
            to="/pieces/new"
            className="btn btn-primary float-right"
            style={{ marginBottom: 20 }}
          >
            Nueva Pieza
          </Link>

          <React.Fragment>
            <p>Mostrando {itemsCount} Piezas</p>
            <SearchBox value={searchQuery} onChange={this.handleSearch} />
            {count > 0 && (
              <React.Fragment>
                <MailingNumbersTable
                  mailingNumbers={pieces}
                  sortColumn={sortColumn}
                  onDelete={this.handleDelete}
                  onSort={this.handleSort}
                  onCopy={this.handleCopy}
                  onDownload={this.handleDownload}
                />
                <Pagination
                  itemsCount={itemsCount}
                  pageSize={pageSize}
                  currentPage={currentPage}
                  onPageChange={this.handlePageChange}
                />
              </React.Fragment>
            )}
          </React.Fragment>
        </div>
      </div>
    );
  }
}

export default Pieces;
