import React, { Component } from "react";
import Table from "../common/table";
import { Link } from "react-router-dom";

class NumbersTable extends Component {
  state = {
    email: "",
  };

  columns = [
    {
      path: "absoluteNumber",
      label: "Orden",
    },
    {
      path: "recurringType",
      label: "Recurrencia",
    },
    { path: "mailingName", label: "Envío" },
    { path: "number", label: "Envío-Número" },
    { path: "absoluteMonth", label: "Mes" },
    { path: "week", label: "Semana" },
    { path: "day", label: "Día" },
    { path: "subject", label: "Asunto" },
    {
      path: "pieceId",
      label: "Ver Pieza",
      content: (number) => (
        <Link to={"/pieces/" + number.pieceId} target="_blank">
          Ver
        </Link>
      ),
    },
  ];

  render() {
    const { numbers, onSort, sortColumn } = this.props;

    return (
      <React.Fragment>
        <Table
          columns={this.columns}
          data={numbers}
          sortColumn={sortColumn}
          onSort={onSort}
        />
      </React.Fragment>
    );
  }
}

export default NumbersTable;
