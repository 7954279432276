import React from "react";
import Joi from "joi-browser";
import { toast } from "react-toastify";
import Form from "../common/form";
import { getContentFromExternalApi } from "../../services/externalApiService";

class ApiDataFetcher extends Form {
  state = {
    data: {
      apiUrl: "",
      resourceId: "",
    },
    errors: {}
  };

  componentDidMount() {
    const { data } = this.state
    const { apiUrl } = this.props;
    data.apiUrl = apiUrl;
    this.setState({ data });
  }

  schema = {
    apiUrl: Joi.string().required().label("Api Url"),
    resourceId: Joi.any().required().label("Id del recurso"),
  };

  doSubmit = async () => {
    const { apiParam, updateContent } = this.props;
    const { apiUrl, resourceId } = this.state.data;
    try {
      const res = await getContentFromExternalApi(apiUrl, resourceId);
      console.log(res, apiParam)
      updateContent(res.data[apiParam]);
    } catch (ex) {
      if (ex.response && ex.response.status !== 200) {
        toast.error("No se pudo obtener la data de la api externa");
      }
    }
  };

  render() {
    return (
      <div>
        {this.renderInput("apiUrl", "Api Url")}
        {this.renderInput("resourceId", "Id del recurso")}
        <button className="btn btn-info" onClick={this.handleSubmit}>Obtener data</button>
      </div>
    );
  }
}

export default ApiDataFetcher;
