import React from "react";
import { Link } from "react-router-dom";
import Joi from "joi-browser";
import Form from "../common/form";
import { getBySubscription } from "../../services/emailService";
import {
  getSubscription,
  saveSubscription,
} from "../../services/subscriptionService";
import { getCategoriesByProgramId } from "../../services/categoryService";
import { getPrograms } from "../../services/programService";
import { getUser } from "../../services/userService";
import { toast } from "react-toastify";
import moment from "moment";
import EmailsTable from "./emailsTable";

class SubscriptionForm extends Form {
  state = {
    data: {
      // user: { _id: "", name: "", lastname: ""},
      userName: "",
      userId: "",
      userLastName: "",
      programId: "",
      userCategoryId: "",
      active: "false",
      paymentStatus: "false",
      week: 1,
      weekNumber: 1,
      day: 1,
      month: 1,
      year: 1,
      initialDay: new Date(),
    },
    programs: [],
    categories: [],
    users: [],
    emails: [],
    errors: {},
  };

  schema = {
    _id: Joi.string(),
    userId: Joi.string().required().label("Usuario"),
    userName: Joi.string().required().label("Nombre de Usuario"),
    userLastName: Joi.string().required().label("Apellido de Usuario"),
    programId: Joi.string().required().label("Programa"),
    userCategoryId: Joi.string().label("Categorías"),
    active: Joi.string().required().label("Activa"),
    paymentStatus: Joi.string().required().label("Estado de pago"),
    week: Joi.number().label("Semana"),
    month: Joi.number().label("Mes"),
    day: Joi.number().label("Día"),
    year: Joi.number().label("Año"),
    weekNumber: Joi.number().label("Semana global"),
    initialDay: Joi.string().label("Fecha de creación"),
  };

  async populatePrograms() {
    const { data } = await getPrograms();
    console.log(data.programs);
    this.setState({ programs: data.programs });
  }

  async populateCategories() {
    const { data } = await getCategoriesByProgramId(this.state.data.programId);
    console.log(data.userCategories);
    this.setState({ categories: data.userCategories });
  }

  async populateSubscriptions() {
    try {
      const subscriptionId = this.props.match.params.id;
      if (subscriptionId === "new") return;

      const res = await getSubscription(subscriptionId);
      const subscription = res.data.subscription;
      this.setState({ data: this.mapToViewModel(subscription) });
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        this.props.history.replace("/not-found");
    }
  }

  async populateUser() {
    const userId = this.props.match.params.userId;
    const res = await getUser(userId);
    const { data } = this.state;
    // data.user = {
    //   _id: res.data.user._id,
    //   name: res.data.user.name,
    //   lastname: res.data.user.lastname
    // }
    data.userId = res.data.user._id;
    data.userName = res.data.user.name;
    data.userLastName = res.data.user.lastname;

    this.setState({ data });
  }

  async componentDidMount() {
    await this.populatePrograms();
    await this.populateSubscriptions();
    const subscriptionId = this.props.match.params.id;
    if (subscriptionId === "new") this.populateUser();
    else this.getEmails(subscriptionId);
  }

  async getEmails(subscriptionId) {
    const { data } = await getBySubscription(subscriptionId);
    this.setState({ emails: data.emails });
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.data.programId !== this.state.data.programId)
      await this.populateCategories();
  }

  mapToViewModel(subscription) {
    return {
      _id: subscription._id,
      userId: subscription.user._id,
      userName: subscription.user.name,
      userLastName: subscription.user.lastname,
      programId: subscription.program._id,
      userCategoryId: subscription.userCategory._id,
      active: subscription.active.toString(),
      paymentStatus: subscription.paymentStatus.toString(),
      year: subscription.year,
      week: subscription.week,
      month: subscription.month,
      day: subscription.day,
      weekNumber: subscription.weekNumber,
      initialDay: moment(subscription.initialDay).format("yyyy-MM-DD"),
    };
  }

  doSubmit = async () => {
    try {
      await saveSubscription(this.state.data);
      this.props.history.push("/subscriptions");
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data);
      }
    }
  };

  handleSort = (sortColumn) => {
    this.setState({ sortColumn });
  };

  render() {
    const subscriptionId = this.props.match.params.id;
    return (
      <div>
        {subscriptionId === "new" && <h2>Crear Suscripción</h2>}
        {subscriptionId !== "new" && <h2>Editar Suscripción</h2>}
        <form onSubmit={this.handleSubmit}>
          {this.renderInput("userName", "Nombre")}
          {this.renderInput("userLastName", "Apellido")}
          {this.renderInput("userId", "UsuarioId")}
          {this.renderSelect("programId", "Programa", this.state.programs)}
          {this.renderSelect(
            "userCategoryId",
            "Categoría",
            this.state.categories
          )}
          {this.renderInput("active", "Activa", "checkbox")}
          {this.renderInput("paymentStatus", "Estado de pago", "checkbox")}
          {this.renderInput("year", "Año")}
          {this.renderInput("month", "Mes")}
          {this.renderInput("week", "Semana")}
          {this.renderInput("day", "Día")}
          {this.renderInput("weekNumber", "Semana global")}
          {this.renderInput("initialDay", "Dia inicial", "date")}

          {this.renderButton("Save")}
        </form>
        {subscriptionId !== "new" && (
          <div className="mt-2">
            <Link
              to={"/categories/" + this.state.data.userCategoryId + "#program"}
              target="_blank"
            >
              Ver Programa
            </Link>
          </div>
        )}
        {this.state.emails.length > 0 && (
          <div className="mt-2">
            <h3>Historial de envíos</h3>
            <EmailsTable
              emails={this.state.emails}
              sortColumn={{ path: "createdAt", order: "desc" }}
              onDelete={this.handleDelete}
              onSort={this.handleSort}
            />
          </div>
        )}
      </div>
    );
  }
}

export default SubscriptionForm;
