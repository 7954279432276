import http from "./httpService";
import { apiUrl } from "../config.json";

const apiEndpoint = apiUrl + "/attachments";

export function saveAttachment(formData, id = null) {
  if (id) return http.put(attachmentUrl(id), formData);
  return http.post(`${apiEndpoint}`, formData);
}

export function getAttachments() {
  return http.get(`${apiEndpoint}`);
}

function attachmentUrl(id) {
  return `${apiEndpoint}/${id}`;
}

export function getAttachment(id) {
  return http.get(attachmentUrl(id));
}

export function deleteAttachment(id) {
  return http.delete(attachmentUrl(id));
}
