import http from "./httpService";
import { apiUrl } from "../config.json";

const apiEndpoint = apiUrl + "/mailings";


function mailingUrl(id) {
    return `${apiEndpoint}/${id}`;
  }

export function getMailings() {
  return http.get(`${apiEndpoint}`);
}

export function getMailingsByProgramId(programId) {
  return http.get(`${apiEndpoint}/by-program/${programId}`);
}

export function getMailing(id) {
  return http.get(mailingUrl(id));
}

export function saveMailing(mailing) {
  if (mailing._id) {
    const body = { ...mailing };
    delete body._id;
    return http.put(mailingUrl(mailing._id), body);
  }

  return http.post(`${apiEndpoint}`, mailing);
}

export function deleteMailing(id) {
  return http.delete(mailingUrl(id));
}
