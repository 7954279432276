import React from "react";
import Joi from "joi-browser";
import Form from "../common/form";

class AddElementForm extends Form {
  state = {
    data: {
      name: "",
      type: "",
      rows: "",
      cells: "",
      width: "",
      height: "",
      href: "",
      text: "",
    },
    elements: [
      { _id: "table", name: "Tabla / Contenedor" },
      { _id: "img", name: "Imagen" },
      { _id: "span", name: "Texto" },
      { _id: "button", name: "Botón" },
      { _id: "div", name: "Caja" }
    ],
    errors: {},
  };

  schema = {
    _id: Joi.string(),
    name: Joi.string().allow("").label("Nombre"),
    type: Joi.string().required().label("Tipo"),
    rows: Joi.number().allow("").label("Lineas"),
    cells: Joi.number().allow("").label("Celdas"),
    width: Joi.any().allow("").label("Ancho"),
    height: Joi.any().allow("").label("Alto"),
    href: Joi.string().allow("").label("Link"),
    text: Joi.string().allow("").label("Texto"),
  };

  doSubmit = () => {
    this.props.addElement(this.state.data);
  };

  render() {
    return (
      <div>
        <h5>Añadir Elemento</h5>
        <form onSubmit={this.handleSubmit}>
          {this.renderInput("name", "Nombre")}
          {this.renderSelect("type", "Tipo", this.state.elements)}
          {this.state.data.type === "table" && (
            <React.Fragment>
              <hr />
              <h6>Contenedor</h6>
              {this.renderInput("rows", "Lineas", "number")}
              {this.renderInput("cells", "Celdas", "number")}
            </React.Fragment>
          )}
          {this.state.data.type === "img" && (
            <React.Fragment>
              <hr />
              <h6>Imagen</h6>
              {this.renderInput("width", "Ancho", "number")}
              {this.renderInput("height", "Alto", "number")}
            </React.Fragment>
          )}
          {this.state.data.type === "div" && (
            <React.Fragment>
              <hr />
              <h6>Caja</h6>
              {this.renderInput("width", "Ancho")}
              {this.renderInput("height", "Alto")}
            </React.Fragment>
          )}
          {this.state.data.type === "button" && (
            <React.Fragment>
              <hr />
              <h6>Botón</h6>
              {this.renderInput("href", "Link")}
              {this.renderInput("text", "Texto")}
            </React.Fragment>
          )}
          {this.state.data.type === "span" && (
            <React.Fragment>
              <hr />
              <h6>Texto</h6>
              {this.renderInput("text", "Texto")}
            </React.Fragment>
          )}
          <hr />
          {this.renderButton("Añadir")}
        </form>
      </div>
    );
  }
}

export default AddElementForm;
