import React, { Component } from "react";
import auth from "../../services/authService";
import { Link } from "react-router-dom";
import Table from "../common/table";
import Preview from "../preview";


class MailingNumbersTable extends Component {
  columns = [
    {
      path: "mailing.name",
      label: "Asunto",
      content: (piece) => (
        <Link to={`/pieces/${piece._id}`}>
          {piece.subject}
        </Link>
      ),
    },
    // {
    //   path: "html",
    //   label: "Asunto",
    //   content: (piece) => (
    //     <div className="preview-container">
    //       <Preview
    //         handleHtmlChange={null}
    //         editable={false}
    //         html={piece.html}
    //         selectedProgram={null}
    //       ></Preview>
    //     </div>
    //   ),
    // },
  ];

  deleteColumn = {
    key: "buttons",
    content: (piece) => (
      <div className="float-right">
        <button
          onClick={() => this.props.onDownload(piece)}
          className="btn btn-warning btn-sm mr-2"
        >
          Descargar
        </button>
        <button
          onClick={() => this.props.onCopy(piece)}
          className="btn btn-success btn-sm"
        >
          Copiar
        </button>
        <button
          onClick={() => this.props.onDelete(piece)}
          className="btn btn-danger btn-sm ml-2"
        >
          Borrar
        </button>
      </div>
    ),
  };

  constructor() {
    super();
    const user = auth.getCurrentUser();
    if (user && user.isAdmin) this.columns.push(this.deleteColumn);
  }

  render() {
    const { mailingNumbers, onSort, sortColumn } = this.props;

    return (
      <Table
        columns={this.columns}
        data={mailingNumbers}
        sortColumn={sortColumn}
        onSort={onSort}
      />
    );
  }
}

export default MailingNumbersTable;
