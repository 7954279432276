import http from "./httpService";
import { apiUrl } from "../config.json";

const apiEndpoint = apiUrl + "/users";


function userUrl(id) {
    return `${apiEndpoint}/${id}`;
  }

export function getUsers() {
  return http.get(`${apiEndpoint}/users`);
}

export function getUser(userId) {
  return http.get(userUrl(userId));
}

export function saveUser(user) {
  if (user._id) {
    const body = { ...user };
    delete body._id;
    return http.put(`${apiEndpoint}/admin/${user._id}`  , body);
  }

  return http.post(`${apiEndpoint}/user`, user);
}

export function deleteUser(userId) {
  return http.delete(`${apiEndpoint}/user/${userId}`);
}
