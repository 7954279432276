import React, { Component } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import TemplateTable from "./table";
import ListGroup from "../common/listGroup";
import Pagination from "../common/pagination";
import {
  getTemplates,
  deleteTemplate,
  downloadTemplate
} from "../../services/templateService";
import { getPrograms } from "../../services/programService";
import { paginate } from "../../utils/paginate";
import _ from "lodash";
import SearchBox from "../searchBox";

class Templates extends Component {
  state = {
    templates: [],
    currentPage: 1,
    pageSize: 4,
    searchQuery: "",
    sortColumn: { path: "name", order: "asc" },
  };

  async componentDidMount() {

    const {data} = await getTemplates()
    // console.log(data)
    this.setState({ templates : data.templates });
  }

  handleDelete = async (template) => {
    const originalTemplates = this.state.templates;
    const templates = originalTemplates.filter((m) => m._id !==template._id);
    this.setState({ templates });

    try {
      await deleteTemplate(template._id);
    } catch (ex) {
      if (ex.response && ex.response.status ===404)
        toast.error("This template has already been deleted.");

      this.setState({ templates: originalTemplates });
    }
  };

  handleDownload = async (template) => {
   
    await downloadTemplate(template)

  };

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };


  handleSearch = (query) => {
    this.setState({
      searchQuery: query,
      currentPage: 1,
    });
  };

  handleSort = (sortColumn) => {
    this.setState({ sortColumn });
  };

  getPagedData = () => {
    const {
      pageSize,
      currentPage,
      sortColumn,
      searchQuery,
      templates: allTemplates,
    } = this.state;

    let filtered = allTemplates;
    console.log(filtered);
    if (searchQuery)
      filtered = allTemplates.filter((m) =>
        m.name.toLowerCase().startsWith(searchQuery.toLowerCase())
      );

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

    const templates = paginate(sorted, currentPage, pageSize);
    // console.log(templates)
    return { totalCount: filtered.length, data: templates };
  };

  render() {
    const { pageSize, currentPage, sortColumn, searchQuery } = this.state;

    const { totalCount, data: templates } = this.getPagedData();

    return (
      <div className="row">
        <div className="col">
          <Link
            to="/templates/new"
            className="btn btn-primary float-right"
            style={{ marginBottom: 20 }}
          >
            Nuevo Template
          </Link>
          {totalCount ===0 && <p>There are no templates in the database.</p>}
          {totalCount > 0 && (
            <React.Fragment>
              <p>Showing {totalCount} templates in the database.</p>
              <SearchBox value={searchQuery} onChange={this.handleSearch} />
              <TemplateTable
                templates={templates}
                sortColumn={sortColumn}
                onDelete={this.handleDelete}
                onDownload={this.handleDownload}
                onSort={this.handleSort}
              />
              <Pagination
                itemsCount={totalCount}
                pageSize={pageSize}
                currentPage={currentPage}
                onPageChange={this.handlePageChange}
              />
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}

export default Templates;
