import React, { Component } from "react";
import auth from "../../services/authService";
import { Link } from "react-router-dom";
import Table from "../common/table";

class MailingNumbersTable extends Component {
  columns = [
    {
      path: "mailing.name",
      label: "Envío",
      content: (mailingNumber) => (
        <Link to={`/mailing-numbers/${mailingNumber._id}`}>
          {mailingNumber.mailing.name}
        </Link>
      ),
    },
    { path: "subject", label: "Asunto" },
    { path: "number", label: "Número" },
  ];

  deleteColumn = {
    key: "buttons",
    content: (mailingNumber) => (
      <div className="float-right">
        <button
          onClick={() => this.props.onDownload(mailingNumber)}
          className="btn btn-warning btn-sm mr-2"
        >
          Descargar
        </button>
        <button
          onClick={() => this.props.onCopy(mailingNumber)}
          className="btn btn-success btn-sm"
        >
          Copiar
        </button>
        <button
          onClick={() => this.props.onDelete(mailingNumber)}
          className="btn btn-danger btn-sm ml-2"
        >
          Borrar
        </button>
      </div>
    ),
  };

  constructor() {
    super();
    const user = auth.getCurrentUser();
    if (user && user.isAdmin) this.columns.push(this.deleteColumn);
  }

  render() {
    const { mailingNumbers, onSort, sortColumn } = this.props;

    return (
      <Table
        columns={this.columns}
        data={mailingNumbers}
        sortColumn={sortColumn}
        onSort={onSort}
      />
    );
  }
}

export default MailingNumbersTable;
