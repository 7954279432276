import http from "./httpService";
import { apiUrl } from "../config.json";

const apiEndpoint = apiUrl + "/pieces";

function pieceUrl(id) {
  return `${apiEndpoint}/${id}`;
}

export function getPieces(page, searchQuery, limit) {
  return http.get(`${apiEndpoint}?page=${page}&searchQuery=${searchQuery}&limit=${limit}`);
}

export function getPiece(id) {
  return http.get(pieceUrl(id));
}

export function savePiece(piece) {

  const body = { ...piece };
  body.html = JSON.stringify(piece.html)
  delete body.templateId;

  const tags = body.tags.map((tag) => tag._id)
  body.tags = tags

  if (piece._id) {
    delete body._id;
    return http.put(pieceUrl(piece._id), body);
  }

  return http.post(`${apiEndpoint}`, body);
}

export function deletePiece(id) {
  return http.delete(pieceUrl(id));
}

export async function downloadPiece(piece) {
  const response = await http.get(`${apiEndpoint}/download/${piece._id}`);
  // console.log(response.data.html)
  const blob = new Blob([response.data.html]);
  let url = window.URL.createObjectURL(blob);
  let a = document.createElement("a");
  a.href = url;
  a.download = `${piece.subject}.html`;
  a.click();
}

export function copyPiece(piece){
  return http.post(`${apiEndpoint}/copy`, piece)
}