import http from "./httpService";
import { apiUrl } from "../config.json";

const apiEndpoint = apiUrl + "/emails";


export function getEmails(page, searchQuery) {
  return http.get(`${apiEndpoint}?page=${page}&searchQuery=${searchQuery}`);
}

export function getRecentEmails(period) {
  return http.get(`${apiEndpoint}/recent/${period}`);
}

export function getBySubscription(subscriptionId) {
  return http.get(`${apiEndpoint}/by-subscription/${subscriptionId}`);
}