import React from "react";
import Joi from "joi-browser";
import Form from "../common/form";
import {
  getTemplate,
  saveTemplate,
  getRootTemplate,
  getRootElement,
  htmlStringToObj
} from "../../services/templateService";
import TemplatePreview from "../templatePreview";
import ImportFromFile from "../common/importFromFile";
import { toast } from "react-toastify";

class TemplateForm extends Form {
  state = {
    data: {
      name: "",
      fonts: "",
      html: "",
    },
    // htmlObject: {
    //   name: "",
    // },
    errors: {},
  };

  schema = {
    _id: Joi.string(),
    name: Joi.string().required().label("Nombre"),
    fonts: Joi.string().allow("").label("Fuentes"),
    html: Joi.any().required().label("Html"),
  };

  async populateTemplate() {
    try {
      const templateId = this.props.match.params.id;
      let template = "";
      if (templateId === "new") {
        template = getRootTemplate();
        console.log()
      } else {
        const res = await getTemplate(templateId);
        template = res.data.template;
      }

      console.log("template...", template.html)

      this.setState({
        data: this.mapToViewModel(template),
        // htmlObject: template.html,
      });
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        this.props.history.replace("/not-found");
    }
  }

  async componentDidMount() {
    await this.populateTemplate();
  }

  mapToViewModel(template) {
    return {
      _id: template._id,
      name: template.name,
      fonts: template.fonts,
      html: template.html//JSON.stringify(template.html),
    };
  }

  doSubmit = async () => {
    
    this.replaceFonts()
    const res = await saveTemplate(this.state.data);

    toast.success("El registro se actualizó con éxito")
   
    if(this.props.match.params.id === "new")
      this.props.history.push(`/templates/${res.data.template._id}`)
      else{
        this.setState({
          data: this.mapToViewModel(res.data.template),
          // htmlObject: template.html,
        });
      }

  };

  replaceFonts = () => {
    const {data} = this.state
    const {fonts, html} = this.state.data
    if(fonts !== ""/* && typeof html !== "string"*/){
      if(html.hasOwnProperty("children") && html.children.length > 0 && html.children[0].name === "head"){
        console.log("entering the head", html.children[0])
        if(html.children[0].hasOwnProperty("children") && html.children[0].children.length > 0){
          for(let x = 0; x <  html.children[0].children.length; x++){
            if(html.children[0].children[x].name === "link" && html.children[0].children[x].hasOwnProperty("attrs")){
              for (const [key, value] of Object.entries(html.children[0].children[x].attrs)) {
                if(key === "rel" && value === "stylesheet"){
                  console.log("deleting the rel")
                  html.children[0].children.splice(x, 1)
                }
                  
              }
            }
          }
        }else{
          console.log("creating the children")
          html.children[0].children = []
        }
        console.log("adding the font")
        html.children[0].children.push({"name" : "link",  attrs : {"rel": "stylesheet", "href": fonts} })
        data.html = html
      }
      this.setState({data})
    }
  }

  deepCopy = (node) => {
    const iterateChildren = (htmlNode) => {
      // console.log("copy this ", htmlNode)
      // let copy = { ...htmlNode };
      // copy._id = this.makeid();
      let copy = { _id: this.makeid()}
        copy.name = htmlNode.name
        if(htmlNode.hasOwnProperty("children")) copy.children = htmlNode.children
        if(htmlNode.hasOwnProperty("content")) copy.content = htmlNode.content
        if(htmlNode.hasOwnProperty("attrs")) copy.attrs = {...htmlNode.attrs}
        if(htmlNode.hasOwnProperty("styles")) copy.styles = {...htmlNode.styles}
      // console.log("into", copy)
      if (copy.hasOwnProperty("children") && copy.children.length > 0) {
        let children = [];
        for (let child of copy.children) {
          let childElement = iterateChildren(child);
          children.push(childElement);
        }
        copy.children = children;
      }
      // else{
      //   copy = { _id: this.makeid()}
      //   copy.name = htmlNode.name
      //   if(htmlNode.hasOwnProperty("content")) copy.content = htmlNode.content
      //   if(htmlNode.hasOwnProperty("attrs")) copy.attrs = {...htmlNode.attrs}
      //   if(htmlNode.hasOwnProperty("styles")) copy.styles = {...htmlNode.styles}
      //   //getCopy(copy)
      // }

      return copy;
    };

    return iterateChildren(node);
  };

  handleHtmlChange = (type, id, data = {}) => {

    // console.log("handleHtmlChange", type, id, data)

    const { data: originalData } = this.state;
    let html = originalData.html;

    // console.log(html)

    const iterateChildren = (htmlNode, position = null, parent = null) => {
      
      if (
        typeof htmlNode !== "undefined" &&
        htmlNode.hasOwnProperty("children") &&
        htmlNode.children.length > 0
      ) {
        for (let x = 0; x < htmlNode.children.length; x++) {

          if (
            type === "removeElement" &&
            typeof htmlNode.children[x].attrs !== "undefined" &&
            htmlNode.children[x].attrs.className === "selected"
          )
            htmlNode.children.splice(x, 1);
            // delete htmlNode.children[x];
          else if ( type === "addTr" || type === "copyTr" || type === "removeTr") {
            if (
              typeof htmlNode.children[x].attrs !== "undefined" &&
              htmlNode.children[x].attrs.className === "selected"
            ) {
              htmlNode.children[x].attrs.className = "";
              if (type === "removeTr") {
                parent.children.splice(position, 1);
              } else if (type === "copyTr") {
                let copy = this.deepCopy(htmlNode);
                parent.children.splice((position+1), 0, copy);
                parent.children.join();
              } 
              else {
                const copy = getRootElement("tr", {cells: htmlNode.children.length});
                parent.children.splice((position+1), 0, copy);
                parent.children.join();
               }
            }

            iterateChildren(htmlNode.children[x], x, htmlNode);
          } else iterateChildren(htmlNode.children[x], x, htmlNode);
        }
      }

      // console.log(htmlNode._id, id)
      if (typeof htmlNode !== "undefined" && htmlNode._id === id) {
        switch (type) {
          case "edit":
            if (data.type === "content") htmlNode[data.type] = data.value;
            else htmlNode[data.type][data.name] = data.value;
            break;
          case "select":
            // console.log("equals?", htmlNode._id, id)
            if (typeof htmlNode.attrs === "undefined") htmlNode.attrs = {};
            htmlNode.attrs.className = "selected";
            console.log("selected", htmlNode)
            break;
          case "unselect":
            delete htmlNode.attrs.className;
            break;
          case "changeImage":
            htmlNode.attrs.src = data.src;
            break;
          case "changeBgImage":
            console.log("changeBgImage")
            htmlNode.attrs.background = data.src
            htmlNode.styles.backgroundRepeat = "no-repeat"
            htmlNode.styles.backgroundSize = "cover"
            htmlNode.styles.backgroundColor = "transparent"
              // htmlNode.styles.backgroundImage = `url:(${data.src})`;
              // console.log(htmlNode)
              break;
          case "transformToAnchor":
            htmlNode.name = "a";
            if (typeof htmlNode.styles === "undefined") htmlNode.styles = {};
            htmlNode.styles["text-decoration"] = "underline";
            htmlNode.styles.color = "blue";
            htmlNode.attrs = { href: "https://",  target:"_blank" };
            htmlNode.attrs["data-name"] = ""
            break;
          case "encapsulateInAnchor":
              const copy = this.deepCopy(htmlNode)
              htmlNode.name = "a";
              htmlNode._id = this.makeid()
              htmlNode.attrs = { href: "https://",  target:"_blank" };
              htmlNode.styles = {"text-decoration": "none"}
              htmlNode.attrs["data-name"] = ""
              htmlNode.attrs["data-apiproperty"] = ""
              delete htmlNode.content
              htmlNode.children = [copy]
              break;
          case "addSpan":
            const newSpan = {
              _id: this.makeid(),
              name: "span",
              styles: {
                "font-size": htmlNode.styles["font-size"],
                "font-weight": htmlNode.styles["font-weight"],
                color: htmlNode.styles.color,
                "font-family": htmlNode.styles["font-family"],
              },
              content: "TEXTO",
            };
            parent.children.splice(position + 1, 0, newSpan);
            parent.children.join();
            break;
          case "copyTd":
              const tdCopy = this.deepCopy(htmlNode)
              parent.children.splice(position + 1, 0, tdCopy);
              parent.children.join();
              break;
          case "addNewLine":
            const br = { _id: this.makeid(), name: "br" };
            parent.children.splice(position + 1, 0, br);
            parent.children.join();
            const newLine = {
              _id: this.makeid(),
              name: "span",
              attrs: { className: "newLine" },
              styles: {
                "font-size": htmlNode.styles["font-size"],
                color: htmlNode.styles.color,
                "font-family": htmlNode.styles["font-family"],
              },
              content: "..............",
            };
            parent.children.splice(position + 2, 0, newLine);
            parent.children.join();
            break;
          case "addElement":
            parent.children.splice(position, 1);
            // data.fonts = this.state.data.fonts
            const el = getRootElement(data.type, data);
            parent.children.push(el)
            break;
          default:
            break;
        }
      }

      return htmlNode;
    };

    originalData.html = iterateChildren(html);

    this.setState({ data: originalData });
  };

  makeid = () => {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < 10; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  getContent = (content) => {
    const {data} = this.state
    // console.log(content)
    data.html = htmlStringToObj(content, "html")
    // console.log("HTML:", data.html)
    this.setState({data})
  }

  render() {
    const templateId = this.props.match.params.id;
    return (
      <div>
        {templateId === "new" && <h2>Crear Template</h2>}
        {templateId !== "new" && <h2>Editar Template</h2>}
        <form onSubmit={this.handleSubmit}>
          {this.renderInput("name", "Nombre")}
          {this.renderInput("fonts", "Google Fonts link")}
          <div style={{ display: "none" }}>
            {this.renderTextarea("html", "Html")}
          </div>

          {this.renderButton("Guardar")}
        </form>
        <div className="my-2">
        <ImportFromFile 
        getContent={this.getContent} 
        />
        </div>
        <div className="my-2">
        <TemplatePreview
          handleHtmlChange={this.handleHtmlChange}
          editable={true}
          html={this.state.data.html}
          // html={this.state.htmlObject}
        ></TemplatePreview>
        </div>
        
      </div>
    );
  }
}

export default TemplateForm;
