import React, { Component } from "react";
import Table from "../common/table";
import EmailModal from "../emails/modal";
import moment from "moment";

class EmailsTable extends Component {
  state = {
    email: "",
  };

  columns = [
    {
      path: "createdAt",
      label: "Fecha de envío",
      content: (email) => (
        <span>{moment(email.createdAt).format("DD/MM/yyyy")}</span>
      ),
    },
    { path: "data.subject", label: "Asunto" },
    { path: "mailingNumber.mailing.name", label: "Envío" },
    { path: "mailingNumber.number", label: "Número" },
    { path: "status", label: "Status" },
    {
      path: "_id",
      label: "ID",
      content: (email) => (
        <a
          onClick={() => this.setState({ email })}
          data-toggle="modal"
          data-target="#exampleModal"
        >
          #{email._id}
        </a>
      ),
    },
    // {
    //   path: "events",
    //   label: "Opens",
    //   content: email => <Link to={`/emails/${email._id}`}>{moment(email.createdAt).format('DD/MM/yyyy')}</Link>
    // }
  ];

  render() {
    const { emails, onSort, sortColumn } = this.props;

    return (
      <React.Fragment>
        <Table
          columns={this.columns}
          data={emails}
          sortColumn={sortColumn}
          onSort={onSort}
        />
        <EmailModal email={this.state.email} />
      </React.Fragment>
    );
  }
}

export default EmailsTable;
