import React, { Component } from "react";
import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import { stateFromHTML } from "draft-js-import-html";
import "./styles.css";

class TextEditor extends Component {
  constructor(props) {
    super(props);
    const contentState = stateFromHTML(props.initialHtml);
    const editorState = EditorState.createWithContent(contentState);
    this.state = {
      editorState, //: EditorState.createEmpty(),
      contentState, // : null//convertFromHTML(this.props.initialHtml)
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.initialHtml !== this.props.initialHtml) {
      // console.log(this.props.initialHtml)
      const contentState = stateFromHTML(this.props.initialHtml);
      // const editorState = EditorState.createWithContent(contentState);
      this.setState({
        // editorState,//: EditorState.createEmpty(),
        contentState, // : null//convertFromHTML(this.pr
      });
    }
  }

  onContentStateChange = (contentState) => {
    // console.log(contentState)
    //  console.log('as HTML:', draftToHtml(contentState));
    this.props.onTextChange(draftToHtml(contentState), this.props.elementId);
  //  console.log("EDITOR CHANGE, ELEMENTID", this.props.elementId)
    // this.setState({ contentState});
  };

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
  };

  render() {
    const { editorState, contentState } = this.state;

    return (
      <Editor
        //    initialContentState={contentState}
        editorState={editorState}
        // defaultEditorState={{color:"#000"}}
        wrapperClassName="demo-wrapper"
        editorClassName="demo-editor"
        onEditorStateChange={this.onEditorStateChange}
        onContentStateChange={this.onContentStateChange}
        toolbar={{
          options: [
            "inline",
            /*'blockType',*/ "fontSize",
            /*'fontFamily',*/ "list",
            "textAlign",
            "colorPicker",
            "link" /*'embedded', 'emoji', 'image', 'remove'*/,
            ,
            "history",
          ],
          inline: { inDropdown: true },
          list: { inDropdown: true },
          textAlign: { inDropdown: true },
          link: { inDropdown: true },
          history: { inDropdown: true },
        }}
      />
    );
  }
}

export default TextEditor;
