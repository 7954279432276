import React, { Component } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import AdminsTable from "./table";
import Pagination from "../common/pagination";
import { getAdmins, deleteAdmin } from "../../services/adminService";
import { paginate } from "../../utils/paginate";
import _ from "lodash";
import SearchBox from "../searchBox";

class Admins extends Component {
  state = {
    admins: [],
    currentPage: 1,
    pageSize: 4,
    searchQuery: "",
    sortColumn: { path: "name", order: "asc" }
  };

  async componentDidMount() {
   
    const { data } = await getAdmins();
    this.setState({ admins: data.admins });
  }

  handleDelete = async admin => {
    const originalAdmins = this.state.admins;
    // console.log(originalAdmins)
    const admins = originalAdmins.filter(m => m._id !==admin._id);
    this.setState({ admins });

    try {
      await deleteAdmin(admin._id);
    } catch (ex) {
      if (ex.response && ex.response.status ===404)
        toast.error("This admin has already been deleted.");

      this.setState({ admins: originalAdmins });
    }
  };


  handlePageChange = page => {
    this.setState({ currentPage: page });
  };

  handleSearch = query => {
    this.setState({ searchQuery: query, currentPage: 1 });
  };

  handleSort = sortColumn => {
    this.setState({ sortColumn });
  };

  getPagedData = () => {
    const {
      pageSize,
      currentPage,
      sortColumn,
      searchQuery,
      admins
    } = this.state;

    let allAdmins = admins
    let filtered = allAdmins;
    // console.log(filtered)
    if (searchQuery)
      filtered = allAdmins.filter(m =>
        m.name.toLowerCase().startsWith(searchQuery.toLowerCase())
      );
      // console.log(filtered)
    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

    const data = paginate(sorted, currentPage, pageSize);
        // console.log(filtered, filtered.length)
    return { totalCount: filtered.length, data };
  };

  render() {
    const { length: count } = this.state.admins;
    const { pageSize, currentPage, sortColumn, searchQuery } = this.state;

    if (count ===0) return <p>There are no admins in the database.</p>;

    const { totalCount, data: admins } = this.getPagedData();
    // console.log(totalCount)
    // console.log(admins)

    return (
        <div className="row">
          
          <div className="col">
            
              <Link
                to="/admins/new"
                className="btn btn-primary float-right mt-4"
                style={{ marginBottom: 20 }}
              >
                Nuevo admin
              </Link>
          
            
            <SearchBox value={searchQuery} onChange={this.handleSearch} />
            <p>Mostrando {totalCount} registros.</p>
            <AdminsTable
              admins={admins}
              sortColumn={sortColumn}
              onDelete={this.handleDelete}
              onSort={this.handleSort}
            />
            <Pagination
              itemsCount={totalCount}
              pageSize={pageSize}
              currentPage={currentPage}
              onPageChange={this.handlePageChange}
            />
          </div>
        </div>
      
    );
  }
}

export default Admins;
