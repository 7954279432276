import React from "react";
import Joi from "joi-browser";
import Form from "../common/form";
import {
  getTag,
  saveTag
} from "../../services/tagService";
import { toast } from "react-toastify";

class TagForm extends Form {
  state = {
    data: {
      name: ""
    },
    errors: {},
  };

  schema = {
    _id: Joi.string(),
    name: Joi.string().required().label("Nombre"),
  };

  async populateTag() {
    try {
      
      const tagId = this.props.match.params.id;
      if (tagId ==="new") return;

      const res = await getTag(tagId);
      const tag = res.data.tag
      this.setState({ data: this.mapToViewModel(tag) });
      
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        this.props.history.replace("/not-found");
    }
  }

  async componentDidMount() {
    await this.populateTag();
  }

  mapToViewModel(tag) {
    return {
      _id: tag._id,
      name: tag.name
    };
  }

  doSubmit = async () => {
    
    await saveTag(this.state.data);

    this.props.history.push(`/tags`)

  };


  render() {
    const tagId = this.props.match.params.id
    return (
      <div>
        {tagId ==='new' && <h2>Crear Tag</h2>}
        {tagId !=='new' && <h2>Editar Tag</h2>}
        <form onSubmit={this.handleSubmit}>
          {this.renderInput("name", "Nombre")}
          {this.renderButton("Guardar")}
        </form>
      </div>
    );
  }
}

export default TagForm;
