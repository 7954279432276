import React from "react";
import Joi from "joi-browser";
import Form from "./common/form";
import {
  sendTest,
} from "../services/mailingNumberService";
import { toast } from "react-toastify";

class SendEmailFormNew extends Form {

  state = {
    data: {
      to: "",
      pieceId: "",
      mailingNumberId: "",
    },
    errors: {},
  };

  componentDidMount = () => {
    
    const data = { ...this.state.data };

      const pieceId = this.props.piece;
      const mailingNumberId = this.props.mailingNumberId;

      data.pieceId = pieceId
      data.mailingNumberId =mailingNumberId

      this.setState({data})
      
  }

  schema = {
    to: Joi.string().label("Destinatatio"),
    pieceId: Joi.string().required().label("Envío"),
    mailingNumberId: Joi.string()
    };


  doSubmit = async () => {
      
    try {
      await sendTest(this.state.data);
      toast.success("Prueba enviada")
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data);
      }
    }
  };


  render() {
    
    return (
      <div>
          <h3>Enviar prueba</h3>
        <form className="mb-3">
          {this.renderInput("mailingNumberId", "Mailing", "hidden")}
          {this.renderInput("to", "Destinatario")}         
          <button type={"button"} onClick={this.doSubmit} className="btn btn-primary">Enviar</button>
        </form>
      </div>
    );
  }
}

export default SendEmailFormNew;
