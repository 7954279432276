import React, { Component } from "react";
import auth from "../../services/authService";
import { Link } from "react-router-dom";
import Table from "../common/table";

class CategoriesTable extends Component {
  columns = [
    {
      path: "name",
      label: "Nombre",
      content: category => <Link to={`/categories/${category._id}`}>{category.name}</Link>
    },
    { path: "program.name", label: "Programa" }
  ];

  deleteColumn = {
    key: "delete",
    content: category => (
      <button
        onClick={() => this.props.onDelete(category)}
        className="btn btn-danger btn-sm"
      >
        Delete
      </button>
    )
  };

  constructor() {
    super();
    const user = auth.getCurrentUser();
    if (user && user.isAdmin) this.columns.push(this.deleteColumn);
  }

  render() {
    const { categories, onSort, sortColumn } = this.props;

    return (
      <Table
        columns={this.columns}
        data={categories}
        sortColumn={sortColumn}
        onSort={onSort}
      />
    );
  }
}

export default CategoriesTable;
