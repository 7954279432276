import React from "react";
import moment from 'moment'
// import { getEmail } from "../../services/emailService";

const emailModal = (props) => {
  const { email } = props
  return (
    <div
      className="modal fade"
      id="exampleModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <h3>Detalles</h3>
            <table className="table table-striped">
              <tbody>
                <tr>
                  <th scope="row">To</th>
                  <td>{email.data && email.data.to}</td>
                </tr>
                <tr>
                  <th scope="row">From</th>
                  <td>{email.data && email.data.from}</td>
                </tr>
                <tr>
                  <th scope="row">Subject</th>
                  <td>{email.data && email.data.subject}</td>
                </tr>
                <tr>
                  <th scope="row">Status</th>
                  <td>{email.status}</td>
                </tr>
                <tr>
                  <th scope="row">ID</th>
                  <td>{email._id}</td>
                </tr>
                <tr>
                  <th scope="row">Sendgrid Id</th>
                  <td>{email.sgMessageId}</td>
                </tr>
                <tr>
                  <th scope="row">Enviado el</th>
                  <td>{moment(email.createdAt).format('DD/MM/yyyy h:mm')}</td>
                </tr>
                <tr>
                  <th scope="row">Status</th>
                  <td>{email.status}</td>
                </tr>
              </tbody>
            </table>
            <h3>Eventos</h3>
            <table className="table table-striped">
              <tbody>
                {email.events && email.events.map( event => (
                  <tr key={event.sg_event_id}>
                    <th scope="row">{event.event}</th>
                    <td>{moment.unix(event.timestamp).format('DD/MM/yyyy h:mm')}</td>
                  </tr>
                ))}
                
                
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default emailModal;
