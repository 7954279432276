import http from "./httpService";

export async function getContentFromExternalApi(apiUrl, resourceId) {
  // return fetch(`${apiUrl}?id=${resourceId}`)
  //  .then( response => {  
  //   return response.json() 
  //  }).then( json => {  
  //    console.log(json)
  //   return json
  //  })
  const res = await fetch(`${apiUrl}?id=${resourceId}`)
  const json = await res.json()
  console.log(json)
  return json 
  //  return res
  //return http.get(`${apiUrl}?id=${resourceId}`);
}

