import React, { Component } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import TagTable from "./table";
import Pagination from "../common/pagination";
import {
  getTags,
  deleteTag
} from "../../services/tagService";
import { paginate } from "../../utils/paginate";
import _ from "lodash";
import SearchBox from "../searchBox";

class Tags extends Component {
  state = {
    tags: [],
    currentPage: 1,
    pageSize: 4,
    searchQuery: "",
    sortColumn: { path: "name", order: "asc" },
  };

  async componentDidMount() {

    const {data} = await getTags()
    this.setState({ tags : data.tags });
  }

  handleDelete = async (tag) => {
    const originalTags = this.state.tags;
    const tags = originalTags.filter((m) => m._id !==tag._id);
    this.setState({ tags });

    try {
      await deleteTag(tag._id);
    } catch (ex) {
      if (ex.response && ex.response.status ===404)
        toast.error("This tag has already been deleted.");

      this.setState({ tags: originalTags });
    }
  };

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };


  handleSearch = (query) => {
    this.setState({
      searchQuery: query,
      currentPage: 1,
    });
  };

  handleSort = (sortColumn) => {
    this.setState({ sortColumn });
  };

  getPagedData = () => {
    const {
      pageSize,
      currentPage,
      sortColumn,
      searchQuery,
      tags: allTags,
    } = this.state;

    let filtered = allTags;
    console.log(filtered);
    if (searchQuery)
      filtered = allTags.filter((m) =>
        m.name.toLowerCase().startsWith(searchQuery.toLowerCase())
      );

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

    const tags = paginate(sorted, currentPage, pageSize);
    // console.log(tags)
    return { totalCount: filtered.length, data: tags };
  };

  render() {
    const { pageSize, currentPage, sortColumn, searchQuery } = this.state;

    const { totalCount, data: tags } = this.getPagedData();

    return (
      <div className="row">
        <div className="col">
          <Link
            to="/tags/new"
            className="btn btn-primary float-right"
            style={{ marginBottom: 20 }}
          >
            Nuevo Tag
          </Link>
          {totalCount ===0 && <p>There are no tags in the database.</p>}
          {totalCount > 0 && (
            <React.Fragment>
              <p>Showing {totalCount} tags in the database.</p>
              <SearchBox value={searchQuery} onChange={this.handleSearch} />
              <TagTable
                tags={tags}
                sortColumn={sortColumn}
                onDelete={this.handleDelete}
                onSort={this.handleSort}
              />
              <Pagination
                itemsCount={totalCount}
                pageSize={pageSize}
                currentPage={currentPage}
                onPageChange={this.handlePageChange}
              />
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}

export default Tags;
