import React from "react";
import Joi from "joi-browser";
import Form from "../common/form";
import { Link } from "react-router-dom";
import { getUser, saveUser } from "../../services/userService";
import { getSubscriptionsByUserId } from "../../services/subscriptionService";
import { toast } from "react-toastify";

class UserForm extends Form {
  state = {
    data: {
      name: "",
      lastname: "",
      email: "",
      password: "",
    },
    subscriptions: [],
    errors: {},
  };

  schema = {
    _id: Joi.string(),
    name: Joi.string().required().label("Nombre"),
    lastname: Joi.string().required().label("Apellido"),
    email: Joi.string().allow("").email().label("Email"),
    password: Joi.string()
      // .required()
      .label("Password"),
  };

  async populateUser() {
    try {
      const userId = this.props.match.params.id;
      if (userId === "new") return;

      const { data } = await getUser(userId);
      this.setState({ data: this.mapToViewModel(data.user) });
      await this.populateSubscriptions();
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        this.props.history.replace("/not-found");
    }
  }

  async populateSubscriptions() {
    try {
      const { data } = await getSubscriptionsByUserId(this.state.data._id);

      this.setState({ subscriptions: data.subscriptions });
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        this.props.history.replace("/not-found");
    }
  }

  async componentDidMount() {
    await this.populateUser();
  }

  mapToViewModel(user) {
    return {
      _id: user._id,
      name: user.name,
      lastname: user.lastname,
      email: user.email,
    };
  }

  doSubmit = async () => {
    try {
      await saveUser(this.state.data);
      this.props.history.push("/users");
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data);
        //   const errors = { ...this.state.errors }
        //   errors.email = ex.response.data
        //   this.setState({ errors })
      }
    }
  };

  render() {
    const userId = this.props.match.params.id;
    const { subscriptions, data: user } = this.state;
    return (
      <div>
        {userId === "new" && <h2>Crear User</h2>}
        {userId !== "new" && <h2>Editar User</h2>}
        <form onSubmit={this.handleSubmit}>
          {this.renderInput("name", "Nombre")}
          {this.renderInput("lastname", "Apellido")}
          {this.renderInput("email", "Email", "email")}
          {userId === "new" &&
            this.renderInput("password", "Password", "password")}

          {this.renderButton("Save")}
        </form>

        <h3 className="mt-5">Suscripciones</h3>
        {subscriptions.length > 0 && (
          <ul className="list-unstyled">
            {subscriptions.map((subscription) => (
              <li>
                <Link to={`/subscriptions/${subscription._id}`}>
                  <button className="btn btn-danger btn-sm">
                    {subscription.program.name}
                  </button>
                </Link>
              </li>
            ))}
          </ul>
        )}

        <Link to={`/subscriptions/new/${user._id}`}>
          <button className="btn btn-danger btn-sm">New</button>
        </Link>
      </div>
    );
  }
}

export default UserForm;
