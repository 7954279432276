import React from "react";
import Joi from "joi-browser";
import Form from "../common/form";
import { getProgram, saveProgram } from "../../services/programService";
import { toast } from "react-toastify";

class ProgramForm extends Form {

  state = {
    data: {
      name: "",
      fromEmail: "",
      apiUrl: ""
    },
    errors: {}
  };

  schema = {
    _id: Joi.string(),
    name: Joi.string()
      .required()
      .label("Nombre"),
      fromEmail: Joi.string()
      .required()
      .label("Remitente"),
      apiUrl: Joi.string()
      .allow("")
      .label("Api Url"),
  };


  async populateProgram() {
    try {
      const programId = this.props.match.params.id;
      if (programId ==="new") return;

      const {data} = await getProgram(programId);
      this.setState({ data: this.mapToViewModel(data.program) });
    } catch (ex) {
      if (ex.response && ex.response.status ===404)
        this.props.history.replace("/not-found");
    }
  }

  async componentDidMount() {
    await this.populateProgram();
  }

  mapToViewModel(program) {
    return {
      _id: program._id,
      name: program.name,
      fromEmail: program.fromEmail,
      apiUrl: program.apiUrl,
    };
  }

  doSubmit = async () => {
    try {
        await saveProgram(this.state.data);
        this.props.history.push("/programs");
      } catch (ex) {
        if (ex.response && ex.response.status ===400) {
          toast.error(ex.response.data);
        //   const errors = { ...this.state.errors }
        //   errors.email = ex.response.data
        //   this.setState({ errors })
        }
      }
  };

  render() {
    const programId = this.props.match.params.id
    return (
      <div>
        {programId ==='new' && <h2>Crear Programa</h2>}
        {programId !=='new' && <h2>Editar Programa</h2>}
        <form onSubmit={this.handleSubmit}>
          {this.renderInput("name", "Nombre")}
          {this.renderInput("fromEmail", "Remitente envío")}
          {this.renderInput("apiUrl", "Api Url x default")}
          {this.renderButton("Save")}
        </form>
      </div>
    );
  }
}

export default ProgramForm;
