import React, { Component } from "react";
import auth from "../../services/authService";
import { Link } from "react-router-dom";
import Table from "../common/table";

class SubscriptionsTable extends Component {
  columns = [
    {
      path: "user.name",
      label: "Nombre",
      content: subscription => <Link to={`/subscriptions/${subscription._id}`}>{subscription.user.name} {subscription.user.lastname}</Link>
    },
    { path: "program.name", label: "Programa" },
  ];

  deleteColumn = {
    key: "delete",
    content: category => (
      <button
        onClick={() => this.props.onDelete(category)}
        className="btn btn-danger btn-sm"
      >
        Delete
      </button>
    )
  };

  constructor() {
    super();
    const user = auth.getCurrentUser();
    if (user && user.isAdmin) this.columns.push(this.deleteColumn);
  }

  render() {
    const { subscriptions, onSort, sortColumn } = this.props;

    return (
      <Table
        columns={this.columns}
        data={subscriptions}
        sortColumn={sortColumn}
        onSort={onSort}
      />
    );
  }
}

export default SubscriptionsTable;
