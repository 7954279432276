import React, { Component } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import AttachmentsTable from "./table";
import Pagination from "../common/pagination";
import { getAttachments, deleteAttachment } from "../../services/attachmentService";
import { paginate } from "../../utils/paginate";
import _ from "lodash";
import SearchBox from "../searchBox";

class Attachments extends Component {
  state = {
    mailings: [],
    attachments: [],
    tags: [],
    currentPage: 1,
    pageSize: 4,
    searchQuery: "",
    sortColumn: { path: "name", order: "asc" },
  };

  async componentDidMount() {
    const attachments = await getAttachments();
     this.setState({ attachments: attachments.data.attachments })
  }

  handleDelete = async (attachment) => {
    const originalAttachments = this.state.attachments;
    const attachments = originalAttachments.filter((m) => m._id !== attachment._id);
    this.setState({ attachments });

    try {
      await deleteAttachment(attachment._id);
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        toast.error("This mailing has already been deleted.");

      this.setState({ attachments: originalAttachments });
    }
  };

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handleSearch = (query) => {
    this.setState({
      searchQuery: query,
      selectedProgram: null,
      selectedCategory: null,
      currentPage: 1,
    });
  };

  handleSort = (sortColumn) => {
    this.setState({ sortColumn });
  };

  getPagedData = () => {
    const {
      pageSize,
      currentPage,
      sortColumn,
      searchQuery,
      attachments: allAttachments,
    } = this.state;

    let filtered = allAttachments;
    console.log(filtered);
    if (searchQuery)
      filtered = allAttachments.filter((m) =>
        m.name.toLowerCase().startsWith(searchQuery.toLowerCase())
      );
   

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

    const attachments = paginate(sorted, currentPage, pageSize);

    return { totalCount: filtered.length, data: attachments };
  };

  render() {
    const { length: count } = this.state.attachments;
    const { pageSize, currentPage, sortColumn, searchQuery } = this.state;

    const { totalCount, data: attachments } = this.getPagedData();

    return (
      <div className="row">
        
        <div className="col">
          <Link
            to="/attachments/new"
            className="btn btn-primary float-right"
            style={{ marginBottom: 20 }}
          >
            Nuevo Adjunto
          </Link>
          {count > 0 && (
            <React.Fragment>
              <p>Mostrando {totalCount} adjuntos.</p>
              <SearchBox value={searchQuery} onChange={this.handleSearch} />
              <AttachmentsTable
                mailings={attachments}
                sortColumn={sortColumn}
                onDelete={this.handleDelete}
                onSort={this.handleSort}
              />
              <Pagination
                itemsCount={totalCount}
                pageSize={pageSize}
                currentPage={currentPage}
                onPageChange={this.handlePageChange}
              />
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}

export default Attachments;
