import React, { Component } from "react";
import auth from "../../services/authService";
import { Link } from "react-router-dom";
import Table from "../common/table";

class AttachmentsTable extends Component {
  columns = [
    {
      path: "name",
      label: "Nombre",
      content: attachment => <Link to={`/attachments/${attachment._id}`}>{attachment.name}</Link>
    },
    {
      path: "location",
      label: "Link",
      content: attachment => <a href={`${attachment.location}`} target="_blank">Ver</a>
    },

  ];

  deleteColumn = {
    key: "delete",
    content: attachment => (
      <button
        onClick={() => this.props.onDelete(attachment)}
        className="btn btn-danger btn-sm"
      >
        Delete
      </button>
    )
  };

  constructor() {
    super();
    const user = auth.getCurrentUser();
    if (user && user.isAdmin) this.columns.push(this.deleteColumn);
  }

  render() {
    const { mailings, onSort, sortColumn } = this.props;

    return (
      <Table
        columns={this.columns}
        data={mailings}
        sortColumn={sortColumn}
        onSort={onSort}
      />
    );
  }
}

export default AttachmentsTable;
