import React, { Component } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import MailingsTable from "./table";
import ListGroup from "../common/listGroup";
import Pagination from "../common/pagination";
import {
  getMailingsByProgramId,
  deleteMailing,
} from "../../services/mailingService";
import { getPrograms } from "../../services/programService";
import { getCategoriesByProgramId } from "../../services/categoryService";
import { paginate } from "../../utils/paginate";
import _ from "lodash";
import SearchBox from "../searchBox";

class Mailings extends Component {
  state = {
    mailings: [],
    programs: [],
    categories: [],
    currentPage: 1,
    pageSize: 4,
    searchQuery: "",
    selectedProgram: null,
    selectedCategory: null,
    sortColumn: { path: "name", order: "asc" },
  };

  async componentDidMount() {
    const { data } = await getPrograms();
    const programs = [{ _id: "", name: "All Programs" }, ...data.programs];
    // console.log(programs)

    let mailings = [];
    for (let program of programs) {
      if (program._id !== "") {
        const data = await getMailingsByProgramId(program._id);
        mailings = mailings.concat(data.data.mailings);
      }
    }
    this.setState({ mailings, programs });
  }

  handleDelete = async (mailing) => {
    const originalMailings = this.state.mailings;
    const mailings = originalMailings.filter((m) => m._id !== mailing._id);
    this.setState({ mailings });

    try {
      await deleteMailing(mailing._id);
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        toast.error("This mailing has already been deleted.");

      this.setState({ mailings: originalMailings });
    }
  };

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handleProgramSelect = async (program) => {
    this.setState({
      selectedProgram: program,
      searchQuery: "",
      currentPage: 1,
    });
    if (program._id === "") return;
    const { data } = await getCategoriesByProgramId(program._id);
    const categories = [
      { _id: "", name: "All Categories" },
      ...data.userCategories,
    ];
    this.setState({ categories });
  };

  handleCategorySelect = async (category) => {
    this.setState({
      selectedCategory: category,
      searchQuery: "",
      currentPage: 1,
    });
  };

  handleSearch = (query) => {
    this.setState({
      searchQuery: query,
      selectedProgram: null,
      selectedCategory: null,
      currentPage: 1,
    });
  };

  handleSort = (sortColumn) => {
    this.setState({ sortColumn });
  };

  getPagedData = () => {
    const {
      pageSize,
      currentPage,
      sortColumn,
      selectedProgram,
      selectedCategory,
      searchQuery,
      mailings: allMailings,
    } = this.state;

    let filtered = allMailings;
    console.log(filtered);
    if (searchQuery)
      filtered = allMailings.filter((m) =>
        m.name.toLowerCase().startsWith(searchQuery.toLowerCase())
      );
    else if (selectedProgram && selectedProgram._id)
      filtered = allMailings.filter(
        (m) => m.program._id === selectedProgram._id
      );
    if (selectedCategory && selectedCategory._id)
      filtered = allMailings.filter((m) =>
        m.userCategories.includes(selectedCategory._id)
      );

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

    const mailings = paginate(sorted, currentPage, pageSize);

    return { totalCount: filtered.length, data: mailings };
  };

  render() {
    const { length: count } = this.state.mailings;
    const { pageSize, currentPage, sortColumn, searchQuery } = this.state;

    const { totalCount, data: mailings } = this.getPagedData();

    return (
      <div className="row">
        <div className="col-3">
          <ListGroup
            items={this.state.programs}
            selectedItem={this.state.selectedProgram}
            onItemSelect={this.handleProgramSelect}
          />
          <div className="mt-2">
            <ListGroup
              items={this.state.categories}
              selectedItem={this.state.selectedCategory}
              onItemSelect={this.handleCategorySelect}
            />
          </div>
        </div>
        <div className="col">
          <Link
            to="/mailings/new"
            className="btn btn-primary float-right"
            style={{ marginBottom: 20 }}
          >
            Nuevo Envío
          </Link>
          {count > 0 && (
            <React.Fragment>
              <p>Mostrando {totalCount} envíos.</p>
              <SearchBox value={searchQuery} onChange={this.handleSearch} />
              <MailingsTable
                mailings={mailings}
                sortColumn={sortColumn}
                onDelete={this.handleDelete}
                onSort={this.handleSort}
              />
              <Pagination
                itemsCount={totalCount}
                pageSize={pageSize}
                currentPage={currentPage}
                onPageChange={this.handlePageChange}
              />
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}

export default Mailings;
