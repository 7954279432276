import http from "./httpService";
import { apiUrl } from "../config.json";

const apiEndpoint = apiUrl + "/users";


function adminUrl(id) {
    return `${apiEndpoint}/admin/${id}`;
  }

export function getAdmins() {
  return http.get(`${apiEndpoint}/admins`);
}

export function getAdmin(adminId) {
  return http.get(adminUrl(adminId));
}

export function saveAdmin(admin) {
  if (admin._id) {
    const body = { ...admin };
    delete body._id;
    return http.put(adminUrl(admin._id), body);
  }

  return http.post(`${apiEndpoint}/admin`, admin);
}

export function deleteAdmin(adminId) {
  return http.delete(adminUrl(adminId));
}
