import React from "react";
import "./styles.css";
import { useState } from "react";
import ImageUpload from "../imageUpload";
import { useEffect } from "react";
import AddElementForm from "../addElement/form";
import { getAddOn } from "../../services/templateService";

const TemplatePreview = (props) => {
  const { handleHtmlChange, editable } = props;
  const [selectedElement, setSelectedElement] = useState(null);
  const [addingNode, setAddingNode] = useState(null);
  const [showTr, setShowTr] = useState(true);

  const mapElement = (element) => {
    element = element.toLowerCase();
    // console.log(element)
    switch (element) {
      case "html":
        return "div";
      case "head":
        return "div";
      case "meta":
        return "meta";
      case "link":
        return "link";
      case "body":
        return "div";

      case "center":
        return "center";
      case "table":
        return "table";
      case "tbody":
        return "tbody";
      case "thead":
        return "thead";
      case "tr":
        return "tr";
      case "td":
        return "td";
      case "img":
        return "img";
      case "span":
        return "span";
      case "p":
        return "p";
      case "a":
        return "a";
      case "br":
        return "br";
      case "em":
        return "em";
      case "u":
        return "u";
      case "s":
        return "s";
      case "code":
        return "code";
      case "sup":
        return "sup";
      case "strong":
        return "strong";
      case "ol":
        return "ol";
      case "ul":
        return "ul";
      case "li":
        return "li";
      default:
        return element;
    }
  };

  const camelize = (str) => {
    if (!str.includes("-")) return str;

    let arr = str.split("-");
    let capital = arr.map((item, index) =>
      index ? item.charAt(0).toUpperCase() + item.slice(1).toLowerCase() : item
    );

    let capitalString = capital.join("");

    return capitalString;
  };

  const mapAttr = (name) => {
    switch (name) {
      case "charset":
        return "charSet";
      case "cellspacing":
        return "cellSpacing";
      case "cellpadding":
        return "cellPadding";
      case "colspan":
        return "colSpan";
      case "class":
        return "className";
      default:
        return name;
    }
  };

  useEffect(() => {
    // console.log("USE EFFECT")
    // if(attrs.hasOwnProperty("className") && attrs.className === "newLine"){
    //   console.log("here it s the new line")
    //   selectElement(htmlNode)
    // }
  });

  const iterateChildren = (htmlNode) => {
    let attrs = {};

    if (typeof htmlNode === "undefined" || typeof htmlNode.name === "undefined")
      return null;

    if (htmlNode.hasOwnProperty("addOn")) {
      if (htmlNode.addOn === "add")
        attrs.onClick = (e) => showAddForm(e, htmlNode);
    } else attrs.onClick = (e) => handleClick(e, htmlNode);

    if (typeof htmlNode.attrs !== "undefined") {
      for (const [key, value] of Object.entries(htmlNode.attrs)) {
        attrs[mapAttr(key)] = value;
      }
    }
    if (htmlNode.name === "a") htmlNode.attrs.target = "_blank";
    if (htmlNode.name === "tr") {
      if (showTr) attrs.className = "tr-highlighted";
      else attrs.className = "";
    }
    if (typeof htmlNode.styles !== "undefined") {
      attrs.style = {};
      for (const [key, value] of Object.entries(htmlNode.styles)) {
        attrs.style[camelize(key)] = value;
      }
      // attrs.style = htmlNode.styles
      // console.log(attrs.style)
    }

    let content = null;
    if (typeof htmlNode.content !== "undefined") content = htmlNode.content;

    // if(htmlNode.name === "div"){
    //   console.log(htmlNode)
    //   console.log(htmlNode.addOn)
    //   console.log(!htmlNode.children || htmlNode.children.length === 0)
    // }
    if (
      htmlNode.name === "td" ||
      (htmlNode.name === "div" && typeof htmlNode.addOn === "undefined")
      // && (!htmlNode.hasOwnProperty("children") || htmlNode.children.length === 0)
    ) {
      //htmlNode.children = [getAddOn("add")];
      if (
        !htmlNode.hasOwnProperty("children") ||
        htmlNode.children.length === 0
      ) {
        htmlNode.children = [];
      }
      const hasAddOn = htmlNode.children.find(
        (child) => typeof child.addOn !== "undefined"
      );
      console.log(hasAddOn);
      if (typeof hasAddOn === "undefined")
        htmlNode.children.push(getAddOn("add"));
    }

    if (
      !htmlNode.hasOwnProperty("children") ||
      htmlNode.children.length === 0
    ) {
      const newReactElement = React.createElement(
        mapElement(htmlNode.name),
        attrs,
        content
      );
      return newReactElement;
    }

    let children = [];

    if (htmlNode.name !== "head") {
      for (let child of htmlNode.children) {
        let childElement = iterateChildren(child);
        children.push(childElement);
      }
    }

    const newReactElement = React.createElement(
      mapElement(htmlNode.name),
      attrs,
      ...children,
      content
    );
    return newReactElement;
  };

  const printTree = (rootNode) => {
    //   console.log(rootNode)
    const res = iterateChildren(rootNode);
    // console.log(res);
    return <div id="template-preview">{res}</div>;
  };

  const printAttrs = () => {
    return Object.entries(selectedElement.attrs).map(([key, value]) => {
      if (key === "className") return;
      return (
        <div className="form-group" key={selectedElement + "-" + key}>
          <label>{key}</label>
          <input
            onChange={(e) => handleChange("attrs", e)}
            type="text"
            name={key}
            className="form-control"
            value={value}
          />
        </div>
      );
    });
  };

  const printStyles = () => {
    return Object.entries(selectedElement.styles).map(([key, value]) => {
      let type = "text";
      if (
        key === "color" ||
        key === "border-color" ||
        key === "background-color"
      )
        type = "color";
      return (
        <div className="form-group" key={selectedElement + "-" + key}>
          <label>{key}</label>
          <input
            onChange={(e) => handleChange("styles", e)}
            type={type}
            name={key}
            className="form-control"
            value={value}
          />
        </div>
      );
    });
  };

  const printContent = () => {
    return (
      <div className="form-group">
        <label>Texto</label>
        <textarea
          onChange={(e) => handleChange("content", e)}
          className="form-control"
          rows="6"
          value={selectedElement.content}
          autoFocus
        ></textarea>
      </div>
    );
  };

  const handleChange = (type, { currentTarget: input }) => {
    handleHtmlChange("edit", selectedElement._id, {
      type,
      name: input.name,
      value: input.value,
    });
  };

  const copyLine = (e) => {
    e.stopPropagation();
    handleHtmlChange("copyTr", selectedElement._id);
    // setSelectedElement(null);
  };

  const copyCell = (e) => {
    e.stopPropagation();
    handleHtmlChange("copyTd", selectedElement._id);
    // setSelectedElement(null);
  };

  const handleKeyPress = (e) => {
    e.stopPropagation();
    if (e.key === "Enter") {
      // console.log("enter press here! ");
      handleHtmlChange("addNewLine", selectedElement._id);
    }
  };

  const addNewLine = (e) => {
    e.stopPropagation();
    handleHtmlChange("addNewLine", selectedElement._id);
  };

  const removeLine = (e) => {
    e.stopPropagation();
    handleHtmlChange("removeTr", selectedElement._id);
  };

  const removeElement = (e) => {
    e.stopPropagation();
    handleHtmlChange("removeElement", selectedElement._id);
    setSelectedElement(null);
  };

  const handleClick = (e, htmlNode) => {
    if (!editable) return;
    e.stopPropagation();
    selectElement(htmlNode);
  };

  const selectElement = (htmlNode) => {
    if (selectedElement && selectedElement._id !== htmlNode._id) {
      console.log("unselect and select...");
      console.log(htmlNode);
      handleHtmlChange("unselect", selectedElement._id);
      setSelectedElement(null);
    }
    if (!selectedElement) {
      console.log(htmlNode);
      setSelectedElement(htmlNode);
      handleHtmlChange("select", htmlNode._id);
    }
  };

  const unselectElement = (e) => {
    e.stopPropagation();
    handleHtmlChange("unselect", selectedElement._id);
    setSelectedElement(null);
  };

  const toogleTr = (e) => {
    e.stopPropagation();
    setShowTr(!showTr);
    // handleHtmlChange("highlightTr", showTr)
  };

  const changeImage = (src) => {
    handleHtmlChange("changeImage", selectedElement._id, { src });
  };

  const changeBgImage = (src) => {
    handleHtmlChange("changeBgImage", selectedElement._id, { src });
  };

  const addSpan = (e) => {
    e.stopPropagation();
    // console.log(selectedElement._id);
    handleHtmlChange("addSpan", selectedElement._id);
  };

  const transformToAnchor = (e) => {
    e.stopPropagation();
    handleHtmlChange("transformToAnchor", selectedElement._id);
  };

  const encapsulateInAnchor = (e) => {
    e.stopPropagation();
    handleHtmlChange("encapsulateInAnchor", selectedElement._id);
  };

  const showAddForm = (e, htmlNode) => {
    e.stopPropagation();
    if (selectedElement) handleHtmlChange("unselect", selectedElement._id);
    setSelectedElement(null);
    setAddingNode(htmlNode);
  };

  const unSelectNode = (e) => {
    e.stopPropagation();
    setAddingNode(null);
  };

  const addElement = (element) => {
    // console.log("tryng to add element...", element);
    handleHtmlChange("addElement", addingNode._id, element);
    setAddingNode(null);
  };

  const addLine = (e) => {
    e.stopPropagation();
    handleHtmlChange("addTr", selectedElement._id);
    // setSelectedElement(null);
  };

  return (
    <div className="row">
      <div
        className={
          !(selectedElement && selectedElement.name === "img")
            ? " col-md-8"
            : " col-md-4"
        }
      >
        {printTree(props.html)}
      </div>

      <div
        className={
          "p-0" +
          (selectedElement && selectedElement.name === "img"
            ? " col-md-8"
            : " col-md-4")
        }
      >
        {selectedElement && (
          <div className="card element-card" id="element-card">
            <div className="card-body">
              <div className="row">
                <div className="col">
                  <button
                    type="button"
                    className=" close"
                    onClick={unselectElement}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              </div>
              <div className="row">
                <div className="col-3">
                  <h5 className="card-title">
                    {selectedElement.name}
                    {/* #{selectedElement._id} */}
                  </h5>
                </div>
                {selectedElement.name === "td" && (
                  <React.Fragment>
                    <div className="col-3 p-0">
                      <button
                        type="button"
                        onClick={copyLine}
                        className="btn btn-outline-primary"
                      >
                        Copiar linea
                      </button>
                    </div>
                    <div className="col-3 p-0">
                      <button
                        type="button"
                        onClick={addLine}
                        className="btn btn-outline-primary"
                      >
                        Agregar linea
                      </button>
                    </div>
                    <div className="col-3 p-0">
                      <button
                        onClick={removeLine}
                        type="button"
                        className="btn btn-outline-primary"
                      >
                        Borrar linea
                      </button>
                    </div>
                    <div className="col-3 p-0">
                      <button
                        type="button"
                        onClick={copyCell}
                        className="btn btn-outline-primary"
                      >
                        Copiar celda
                      </button>
                    </div>
                  </React.Fragment>
                )}
                <div className="col-3 p-0">
                  <button
                    type="button"
                    onClick={removeElement}
                    className="btn btn-outline-primary"
                  >
                    Borrar
                  </button>
                </div>
                {selectedElement.name === "span" && (
                  <div className="col-3 p-0">
                    <button
                      type="button"
                      onClick={transformToAnchor}
                      className="btn btn-outline-primary"
                    >
                      Hacer link
                    </button>
                  </div>
                )}
                {(selectedElement.name === "span" ||
                  selectedElement.img === "p" ||
                  selectedElement.name === "img" ||
                  selectedElement.name === "div") && (
                  <div className="col-3 p-0">
                    <button
                      type="button"
                      onClick={encapsulateInAnchor}
                      className="btn btn-outline-primary"
                    >
                      Encapsular en link
                    </button>
                  </div>
                )}
              </div>

              {selectedElement.name === "img" && (
                <div className="row mt-2">
                  <div className="col">
                    <ImageUpload
                      aspect={{
                        x: selectedElement.attrs.width,
                        y: selectedElement.attrs.height,
                      }}
                      changeImage={changeImage}
                    />
                  </div>
                </div>
              )}

              <ul className="list-group list-group-flush">
                {typeof selectedElement.content !== "undefined" && (
                  <li className="list-group-item">
                    <h6>Contenido</h6>
                    {printContent()}
                    <button
                      type="button"
                      onClick={addNewLine}
                      className="btn btn-outline-primary"
                    >
                      Nueva línea
                    </button>
                    <button
                      type="button"
                      onClick={addSpan}
                      className="btn btn-outline-primary"
                    >
                      Añadir texto
                    </button>
                  </li>
                )}

                {typeof selectedElement.styles !== "undefined" && (
                  <li className="list-group-item">
                    <h6>Estilos</h6>
                    {printStyles()}
                  </li>
                )}

                {typeof selectedElement.attrs !== "undefined" && (
                  <li className="list-group-item">
                    <h6>Atributos</h6>
                    {printAttrs()}
                    {selectedElement.name === "td" && (
                      <div className="row mt-2">
                        <div className="col">
                          <h6>Imagen de fondo</h6>
                          <ImageUpload
                            aspect={{
                              x: selectedElement.attrs.width,
                              y: selectedElement.attrs.height,
                            }}
                            changeImage={changeBgImage}
                          />
                        </div>
                      </div>
                    )}
                  </li>
                )}
              </ul>
            </div>
          </div>
        )}

        {/* showAddForm */}
        {addingNode && (
          <div className="card" id="element-card">
            <div className="card-body">
              <div className="row">
                <div className="col">
                  <button
                    type="button"
                    className=" close"
                    onClick={unSelectNode}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <AddElementForm addElement={addElement} />
                </div>
              </div>
            </div>
          </div>
        )}

        {/* endaddform */}

        <button
          type="button"
          onClick={toogleTr}
          className="btn btn-outline-primary"
          id="toogle-tr-button"
        >
          Grilla
        </button>
      </div>
    </div>
  );
};

export default TemplatePreview;
