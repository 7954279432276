import React, { Component } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Login from "./components/login";
import Logout from "./components/logout";
import Home from "./components/home";
import Admins from "./components/admins";
import AdminForm from "./components/admins/form";
import Users from "./components/users";
import UserForm from "./components/users/form";
import Programs from "./components/programs";
import ProgramForm from "./components/programs/form";
import Categories from "./components/categories";
import CategoryForm from "./components/categories/form";
import Mailings from "./components/mailings";
import MailingForm from "./components/mailings/form";
import Attachments from "./components/attachments";
import AttachmentsForm from "./components/attachments/form";
import Subscriptions from "./components/subscriptions";
import SubscriptionForm from "./components/subscriptions/form";
import Emails from "./components/emails";
import Templates from "./components/templates";
import TemplateForm from "./components/templates/form";
import Tags from "./components/tags";
import TagForm from "./components/tags/form";
import MailingNumbers from "./components/mailingNumbers";
import MailingNumberForm from "./components/mailingNumbers/form";
import Pieces from "./components/pieces"
import PieceForm from "./components/pieces/form"
import NavBar from "./components/navBar";
import ProtectedRoute from "./components/common/protectedRoute";
import auth from "./services/authService";
import "react-toastify/dist/ReactToastify.css";

class App extends Component {
  state = {};

  componentDidMount() {
    const user = auth.getCurrentUser();
    this.setState({ user });
  }

  render() {
    const { user } = this.state;

    return (
      <div className="container-fluid">
        <ToastContainer />
        <NavBar user={user} />
        <div className="container py-4">
          <Switch>
            <Route path="/login" component={Login} />
            <Route path="/logout" component={Logout} />
            <ProtectedRoute path="/home" component={Home} />
            <ProtectedRoute path="/admins/:id" component={AdminForm} />
            <ProtectedRoute path="/admins" component={Admins} />
            <ProtectedRoute path="/programs/:id" component={ProgramForm} />
            <ProtectedRoute path="/programs" component={Programs} />
            <ProtectedRoute path="/categories/:id" component={CategoryForm} />
            <ProtectedRoute path="/categories" component={Categories} />
            <ProtectedRoute path="/mailings/:id" component={MailingForm} />
            <ProtectedRoute path="/mailings" component={Mailings} />
            <ProtectedRoute path="/attachments/:id" component={AttachmentsForm} />
            <ProtectedRoute path="/attachments" component={Attachments} />
            <ProtectedRoute path="/mailing-numbers/:id" component={MailingNumberForm} />
            <ProtectedRoute path="/mailing-numbers" component={MailingNumbers} />
            <ProtectedRoute path="/pieces/:id" component={PieceForm} />
            <ProtectedRoute path="/pieces" component={Pieces} />
            <ProtectedRoute path="/templates/:id" component={TemplateForm} />
            <ProtectedRoute path="/templates" component={Templates} />
            <ProtectedRoute path="/tags/:id" component={TagForm} />
            <ProtectedRoute path="/tags" component={Tags} />

            <ProtectedRoute path="/users/:id" component={UserForm} />
            <ProtectedRoute path="/users" component={Users} />
            <ProtectedRoute path="/subscriptions/:id/:userId" component={SubscriptionForm} />
            <ProtectedRoute path="/subscriptions/:id" component={SubscriptionForm} />
            <ProtectedRoute path="/subscriptions" component={Subscriptions} />
            {/* <ProtectedRoute path="/emails/:id" component={EmailForm} /> */}
            <ProtectedRoute path="/emails" component={Emails} />
            <Redirect from="/" exact to="/login" />
            <Redirect to="/not-found" />
          </Switch>
        </div>
      </div>
    );
  }
}

export default App;
