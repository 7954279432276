import React from "react";
import { Link, NavLink } from "react-router-dom";
import "./styles.css";

const NavBar = ({ user }) => {
  console.log(user);
  return (
    <nav className="navbar navbar-expand-lg navbar-light sticky-top">
      <Link className="navbar-brand" to="/">
        Content Delivery System
      </Link>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNavAltMarkup"
        aria-controls="navbarNavAltMarkup"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon" />
      </button>
      <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
        <div className="navbar-nav">
          {user && (
            <React.Fragment>
              <NavLink className="nav-item nav-link" to="/home">
                Reportes
              </NavLink>
              <NavLink className="nav-item nav-link" to="/emails">
                Emails
              </NavLink>
              <NavLink className="nav-item nav-link" to="/admins">
                Admins
              </NavLink>
              <NavLink className="nav-item nav-link" to="/programs">
                Programas
              </NavLink>
              <NavLink className="nav-item nav-link" to="/categories">
                Categorías
              </NavLink>
              <NavLink className="nav-item nav-link" to="/mailings">
                Envíos
              </NavLink>
              
              {/* <NavLink className="nav-item nav-link" to="/mailing-numbers">
                Números
              </NavLink> */}
              <NavLink className="nav-item nav-link" to="/pieces">
                Piezas
              </NavLink>
              <NavLink className="nav-item nav-link" to="/tags">
                Tags
              </NavLink>
              <NavLink className="nav-item nav-link" to="/attachments">
                Adjuntos
              </NavLink>
              <NavLink className="nav-item nav-link" to="/templates">
                Templates
              </NavLink>
              <NavLink className="nav-item nav-link" to="/users">
                Usuarios
              </NavLink>
              <NavLink className="nav-item nav-link" to="/subscriptions">
                Suscripciones
              </NavLink>

              <NavLink className="nav-item nav-link" to="/logout">
                Logout
              </NavLink>
            </React.Fragment>
          )}
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
