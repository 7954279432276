import React, { Component } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import UsersTable from "./table";
import Pagination from "../common/pagination";
import { getUsers, deleteUser } from "../../services/userService";
import { paginate } from "../../utils/paginate";
import _ from "lodash";
import SearchBox from "../searchBox";

class Users extends Component {
  state = {
    users: [],
    currentPage: 1,
    pageSize: 4,
    searchQuery: "",
    sortColumn: { path: "name", order: "asc" },
  };

  async componentDidMount() {
    const { data } = await getUsers();
    this.setState({ users: data.users });
  }

  handleDelete = async (user) => {
    const originalUsers = this.state.users;
    // console.log(originalUsers)
    const users = originalUsers.filter((m) => m._id !== user._id);
    this.setState({ users });

    try {
      await deleteUser(user._id);
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        toast.error("This user has already been deleted.");

      this.setState({ users: originalUsers });
    }
  };

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handleSearch = (query) => {
    this.setState({ searchQuery: query, currentPage: 1 });
  };

  handleSort = (sortColumn) => {
    this.setState({ sortColumn });
  };

  getPagedData = () => {
    const {
      pageSize,
      currentPage,
      sortColumn,
      searchQuery,
      users,
    } = this.state;

    let allUsers = users;
    let filtered = allUsers;
    // console.log(filtered)
    if (searchQuery)
      filtered = allUsers.filter((m) =>
        m.email.toLowerCase().startsWith(searchQuery.toLowerCase())
      );
    // console.log(filtered)
    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

    const data = paginate(sorted, currentPage, pageSize);
    // console.log(filtered, filtered.length)
    return { totalCount: filtered.length, data };
  };

  render() {
    const { length: count } = this.state.users;
    const { pageSize, currentPage, sortColumn, searchQuery } = this.state;

    const { totalCount, data: users } = this.getPagedData();
    // console.log(totalCount)
    // console.log(users)

    return (
      <div className="row">
        <div className="col">
          <Link
            to="/users/new"
            className="btn btn-primary float-right mt-4"
            style={{ marginBottom: 20 }}
          >
            Nuevo Usuario
          </Link>

          {count > 0 && (
            <React.Fragment>
              <SearchBox value={searchQuery} onChange={this.handleSearch} />
              <p>Mostrando {totalCount} registros.</p>
              <UsersTable
                users={users}
                sortColumn={sortColumn}
                onDelete={this.handleDelete}
                onSort={this.handleSort}
              />
              <Pagination
                itemsCount={totalCount}
                pageSize={pageSize}
                currentPage={currentPage}
                onPageChange={this.handlePageChange}
              />
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}

export default Users;
