import React, { Component } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import MailingNumbersTable from "./table";
import ListGroup from "../common/listGroup";
import Pagination from "../common/pagination";
import {
  getMailingNumbersByProgramId,
  deleteMailingNumber,
  copyMailingNumber,
  downloadMailingNumber
} from "../../services/mailingNumberOldService";
import { getPrograms } from "../../services/programService";
import { getTags } from "../../services/tagService";
import {  getMailingsByProgramId } from "../../services/mailingService";
import { paginate } from "../../utils/paginate";
import _ from "lodash";
import SearchBox from "../searchBox";

class MailingNumbers extends Component {
  state = {
    mailingNumbers: [],
    programs: [],
    tags: [],
    mailings: [],
    currentPage: 1,
    pageSize: 4,
    searchQuery: "",
    selectedProgram: null,
    selectedMailing: null,
    selectedTag: null,
    sortColumn: { path: "name", order: "asc" },
  };

  async componentDidMount() {
    const { data } = await getPrograms();
    const programs = [{ _id: "", name: "Todos los Programas" }, ...data.programs];
    // console.log(programs)

    let mailingNumbers = [];
    for (let program of programs) {
      if (program._id !=="") {
        const data = await getMailingNumbersByProgramId(program._id);
        mailingNumbers = mailingNumbers.concat(data.data.mailingNumbers);
      }
    }

    const { data: tagsData } = await getTags();
    const tags = [{ _id: "", name: "Todos los Tags" }, ...tagsData.tags];
    
    this.setState({ mailingNumbers, programs, tags });
  }

  handleDelete = async (mailingNumber) => {
    const originalMailingNumbers = this.state.mailingNumbers;
    const mailingNumbers = originalMailingNumbers.filter(
      (m) => m._id !==mailingNumber._id
    );
    this.setState({ mailingNumbers });

    try {
      await deleteMailingNumber(mailingNumber._id);
    } catch (ex) {
      if (ex.response && ex.response.status ===404)
        toast.error("This mailingNumber has already been deleted.");

      this.setState({ mailingNumbers: originalMailingNumbers });
    }
  };

  handleDownload = async (template) => {
   
    await downloadMailingNumber(template)

  };

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handleProgramSelect = async (program) => {
    this.setState({
      selectedProgram: program,
      searchQuery: "",
      currentPage: 1,
    });
    if (program._id ==="") return;
    const { data } = await getMailingsByProgramId(program._id);
    const mailings = [{ _id: "", name: "Todos los Mailings" }, ...data.mailings];
    this.setState({ mailings });
  };

  handleMailingSelect = async (mailing) => {
    this.setState({
      selectedMailing: mailing,
      searchQuery: "",
      currentPage: 1,
    });
  };

  handleTagSelect = async (tag) => {
    this.setState({
      selectedTag: tag,
      searchQuery: "",
      currentPage: 1,
    });
  };

  handleSearch = (query) => {
    this.setState({
      searchQuery: query,
      selectedProgram: null,
      selectedMailing: null,
      currentPage: 1,
    });
  };

  handleSort = (sortColumn) => {
    this.setState({ sortColumn });
  };

  getPagedData = () => {
    const {
      pageSize,
      currentPage,
      sortColumn,
      selectedProgram,
      selectedMailing,
      selectedTag,
      searchQuery,
      mailingNumbers: allMailingNumbers,
    } = this.state;

    let filtered = allMailingNumbers;
    // console.log(filtered)
    if (searchQuery)
      filtered = allMailingNumbers.filter((m) =>
        m.subject.toLowerCase().startsWith(searchQuery.toLowerCase())
      );
    else if (selectedProgram && selectedProgram._id)
      filtered = allMailingNumbers.filter(
        (m) => m.program._id ===selectedProgram._id
      );
    if (selectedMailing && selectedMailing._id)
      filtered = allMailingNumbers.filter(
        (m) => m.mailing ===selectedMailing._id
      );

      if (selectedTag && selectedTag._id){
      console.log(selectedTag._id, allMailingNumbers)
      filtered = allMailingNumbers.filter((m) =>
        m.tags.find( tag => tag._id === selectedTag._id )
      );
      }

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

    const mailingNumbers = paginate(sorted, currentPage, pageSize);

    return { totalCount: filtered.length, data: mailingNumbers };
  };

  handleCopy = async (mailingNumber) => {
    // console.log(mailingNumber)
    const res = await copyMailingNumber(mailingNumber)
     console.log(res.data.mailingNumber)
    let allMailingNumbers = this.state.mailingNumbers
    allMailingNumbers.push(res.data.mailingNumber)
    console.log(allMailingNumbers)
    this.setState({mailingNumbers: allMailingNumbers})
  };

  render() {
    const { length: count } = this.state.mailingNumbers;
    const { pageSize, currentPage, sortColumn, searchQuery } = this.state;

    const { totalCount, data: mailingNumbers } = this.getPagedData();

    return (
      <div className="row">
        {count > 0 && (
          <div className="col-3">
            <ListGroup
              items={this.state.programs}
              selectedItem={this.state.selectedProgram}
              onItemSelect={this.handleProgramSelect}
            />
            <div className="mt-2">
              <ListGroup
                items={this.state.mailings}
                selectedItem={this.state.selectedMailing}
                onItemSelect={this.handleMailingSelect}
              />
            </div>
            <div className="mt-2">
              <ListGroup
                items={this.state.tags}
                selectedItem={this.state.selectedTag}
                onItemSelect={this.handleTagSelect}
              />
            </div>
          </div>
        )}
        <div className="col">
          <Link
            to="/mailing-numbers/new"
            className="btn btn-primary float-right"
            style={{ marginBottom: 20 }}
          >
            Nuevo número de mailing
          </Link>
          
          {count > 0 && (
            <React.Fragment>
              <p>Mostrando {totalCount} números de mailings.</p>
              <SearchBox value={searchQuery} onChange={this.handleSearch} />
              <MailingNumbersTable
                mailingNumbers={mailingNumbers}
                sortColumn={sortColumn}
                onDelete={this.handleDelete}
                onCopy={this.handleCopy}
                onSort={this.handleSort}
                onDownload={this.handleDownload}
              />
              <Pagination
                itemsCount={totalCount}
                pageSize={pageSize}
                currentPage={currentPage}
                onPageChange={this.handlePageChange}
              />
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}

export default MailingNumbers;
