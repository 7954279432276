import http from "./httpService";
import { apiUrl } from "../config.json";

const apiEndpoint = apiUrl + "/programs";


function programUrl(id) {
    return `${apiEndpoint}/${id}`;
  }

export function getPrograms() {
  return http.get(`${apiEndpoint}`);
}

export function getProgram(adminId) {
  return http.get(programUrl(adminId));
}

export function saveProgram(program) {
  if (program._id) {
    const body = { ...program };
    delete body._id;
    return http.put(programUrl(program._id), body);
  }

  return http.post(`${apiEndpoint}`, program);
}

export function deleteProgram(programId) {
  return http.delete(programUrl(programId));
}
