export function getWeekDays() {
    return weekDays;
}

export function getMonths() {
    return months;
}

export function getMonthNumbers() {
    let monthNumber = []
    for(let x = 1; x <= 12; x++)
        monthNumber.push({ _id: x, name: x })
    return monthNumber
}

export function getMonthDays(){
    let monthDays = []
    for(let x = 0; x <= 31; x++)
        monthDays.push({ _id: x, name: x })
    return monthDays
}

const weekDays = [
    { _id: 0, name: "Domingo" },
    { _id: 1, name: "Lunes" },
    { _id: 2, name: "Martes" },
    { _id: 3, name: "Miércoles" },
    { _id: 4, name: "Jueves" },
    { _id: 5, name: "Viernes" },
    { _id: 6, name: "Sábado" }
]

const months = [
    { _id: 0, name: "Enero" },
    { _id: 1, name: "Febrero" },
    { _id: 2, name: "Marzo" },
    { _id: 3, name: "Abril" },
    { _id: 4, name: "Mayo" },
    { _id: 5, name: "Junio" },
    { _id: 6, name: "Julio" },
    { _id: 7, name: "Agosto" },
    { _id: 8, name: "Septiembre" },
    { _id: 9, name: "Octubre" },
    { _id: 10, name: "Noviembre" },
    { _id: 11, name: "Diciembre" }
]

