import http from "./httpService";
import { apiUrl } from "../config.json";
import inlineStyle2Json from "inline-style-2-json";

const apiEndpoint = apiUrl + "/templates";

function templateUrl(id) {
  return `${apiEndpoint}/${id}`;
}

export function getTemplates() {
  return http.get(`${apiEndpoint}`);
}

// export function getTemplatesByProgramId(programId) {
//   return http.get(`${apiEndpoint}/by-program/${programId}`);
// }

export function getTemplate(id) {
  return http.get(templateUrl(id));
}

export function saveTemplate(category) {
  const body = { ...category };

  if (typeof body.html !== "string") body.html = JSON.stringify(body.html);

  if (category._id) {
    delete body._id;
    return http.put(templateUrl(category._id), body);
  }

  return http.post(`${apiEndpoint}`, body);
}

export function deleteTemplate(id) {
  return http.delete(templateUrl(id));
}

export async function downloadTemplate(template) {
  const response = await http.get(`${apiEndpoint}/download/${template._id}`);
  // console.log(response.data.html)
  const blob = new Blob([response.data.html]);
  let url = window.URL.createObjectURL(blob);
  let a = document.createElement("a");
  a.href = url;
  a.download = `${template.name}.html`;
  a.click();
}

const makeid = () => {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < 10; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

function getRootImage(data) {
  return {
    _id: makeid(),
    name: "img",
    attrs: {
      src: `https://via.placeholder.com/${data.width}x${data.height}`,
      width: data.width,
      height: data.height,
      "border-radius": 0,
      "data-name": data.name,
      "data-apiproperty": ""
    },
  };
}

// function extractFamilyFont(link){
//   let startPos = link.indexOf("family=")
//   let endPos = link.indexOf("&display")

//   return
// }
// (data.fonts === "" ? "Arial, Helvetica, sans-serif" : extractFamilyFont(data.fonts))

function getRootText(data) {
  return {
    _id: makeid(),
    name: "span",
    styles: {
      "font-family": "Arial, Helvetica, sans-serif",
      color: data.hasOwnProperty("color") ? data.color : "#000000",
      "font-weight": "300",
      "font-style": "normal",
      "font-size": "14px",
      "text-decoration": "none",
      "text-transform": "none",
    },
    attrs: { "data-name": data.name,
    "data-apiproperty": "" },
    content: data.text,
  };
}

function getRootTable(data) {
  let tableChildren = [];
  
  for (let x = 0; x < parseInt(data.rows); x++) {
    const tr = getRootTr(data);
    tableChildren.push(tr);
  }

  const rootTable = {
    _id: makeid(),
    name: "table",
    attrs: {
      border: "0",
      width: "100%",
      cellspacing: "0",
      cellpadding: "0",
    },
    styles: {
      borderCollapse: "collapse"
    },
    children: [
      {
        _id: makeid(),
        name: "tbody",
        children: tableChildren,
      },
    ],
  }

  const rootTd = {
    _id: makeid(),
    name: "td",
    attrs: { width: "100%", "vertical-align" : "middle", "align" : "center", height: "auto", "data-name": data.name, "data-apiproperty": "" },
    styles: {
      // padding: "2.5%",
      padding: "0",
      "background-color": "#ffffff",
      "opacity": "1",
      "border-style": "solid",
      "border-color": "#000000",
      // "border-width": "1px",
      "border-width": "0",
      "text-align": "center",
      "position": "relative"
    },
    children: [rootTable]
    }
    
  const rootTr = {
    _id: makeid(),
    name: "tr",
    children: [rootTd],
  }

  return {
    _id: makeid(),
    name: "table",
    attrs: {
      border: "0",
      width: "100%",
      cellspacing: "0",
      cellpadding: "0",
    },
    styles: {
      borderCollapse: "collapse"
    },
    children: [
      {
        _id: makeid(),
        name: "tbody",
        children: [rootTr],
      },
    ],
  };
}

function getRootTr(data) {
  let tds = [];
  for (let j = 0; j < parseInt(data.cells); j++) {
    const td = {
      _id: makeid(),
      name: "td",
      attrs: { width: `${(100 / data.cells).toFixed(2)}%`, height: "auto", "data-apiproperty": ""  },
      styles: {
        // padding: "2.5%",
        padding: "0",
        "background-color": "#ffffff",
        "opacity": "1",
        "border-style": "solid",
        "border-color": "#000000",
        // "border-width": "1px",
        "border-width": "0",
        "text-align": "center"
      },
      // children: [getAddOn("add")],
    };
    tds.push(td);
  }
  const tr = {
    _id: makeid(),
    name: "tr",
    children: tds,
  };
  return tr;
}

function getRootButton(data) {

  data.color = "#ffffff"
  const div = {
    _id: makeid(),
    name: "div",
    styles: {
      "background-color": "#000000",
      "opacity": "1",
      color: "#ffffff",
      "max-height": "40px",
      "max-width": "120px",
      "height": "40px",
      "width": "120px",
      margin:"auto",
      padding: "7.5px",
      "text-align": "center",
      "border-style": "solid",
      "border-color": "#ffffff",
      // "border-width": "1px",
      "border-width": "0",
      "border-radius": "0",
    },
    attrs: { "data-name": data.name },
    children: [getRootText(data)],
  }

  return {
    _id: makeid(),
    name: "a",
    attrs: { "data-name": data.name, href: data.href, target: "_blank" },
    styles: { "text-decoration": "none"},
    children: [div],
  };
}

function getRootDiv(data) {

  const div = {
    _id: makeid(),
    name: "div",
    styles: {
      "background-color": "#000000",
      "opacity": "1",
      color: "#ffffff",
      "max-height": data.height,
      "max-width": data.width,
      "height": data.height,
      "width": data.width,
      margin:"auto",
      padding: "7.5px",
      "text-align": "center",
      "border-style": "solid",
      "border-color": "#ffffff",
      // "border-width": "1px",
      "border-width": "0",
      "border-radius": "0",
      "position": "relative",
      "top": "0",
      "left": "0",
      "right": "0",
      "bottom": "0"
    },
    attrs: { "data-name": data.name },
    // children: [getAddOn()],
  }

  return div
}

export function getRootElement(element, data) {
  switch (element) {
    case "img":
      return getRootImage(data);
      case "div":
        return getRootDiv(data);
    case "span":
      return getRootText(data);
    case "table":
      return getRootTable(data);
    case "tr":
      return getRootTr(data);
    case "button":
      return getRootButton(data);
    default:
      break;
  }
}

// export function getCopy(original){
//   console.log("copying,,,", original)
//   return {
//     _id: makeid(),
//     name: original.name,
//     attrs: original.attrs,
//     styles: original.styles,
//     content: original.content
//   }
// }

export function getAddOn(sign) {
  return {
    _id: makeid(),
    name: "div",
    addOn: sign,
    attrs: { class: "addElement" },
    content: "+",
  };
}

export /*async*/ function htmlStringToObj(htmlInput, origin = null) {
  // console.log(htmlInput);

  let json, html;

  const iterateChildren = /*async*/ (htmlNode) => {
    let children = [];

    if (htmlNode.hasOwnProperty("children") && htmlNode.children.length > 0) {
      for (let child of htmlNode.children) {
        let childElement = /*await*/ iterateChildren(child);
        children.push(childElement);
      }
    }

    if (typeof htmlNode.styles !== "undefined") {
      for (const [key, value] of Object.entries(htmlNode.styles)) {
        delete htmlNode.styles[key];
        htmlNode.styles[camelCasetoKebab(key.trim())] = value.trim();
      }
    }

    return {
      _id: makeid(),
      name: htmlNode.name,
      attrs: htmlNode.attrs,
      styles: htmlNode.styles,
      content: htmlNode.content,
      children: children,
    };
  };

  function mapDOM(element, json, origin) {
    var treeObject = {};

    let doc = new DOMParser().parseFromString(element, "text/html");
    element = doc.querySelectorAll(origin ? origin : "body");

    // console.log(element)
    //Recursively loop through DOM elements and assign properties to object
    function treeHTML(element, object) {
      if (origin === "html") {
        if (
          typeof element.nodeName === "undefined" ||
          element.nodeName === "title" ||
          element.nodeName === "link" ||
          element.nodeName === "script" ||
          element.nodeName === "meta"
        )
          return null;
        if (
          element.nodeName === "#text" &&
          !(
            element.parentNode.nodeName === "SPAN" ||
            element.parentNode.nodeName === "P"
          )
        )
          return null;
        if (element.nodeName === "#text") object["name"] = "SPAN";
        else {
          // if(element.nodeName === "SPAN")
          //    object["name"] = "P"
          // else
          object["name"] = element.nodeName;
        }
      } else {
        if (element.nodeName === "#text") object["name"] = "SPAN";
        else if (element.nodeName === "INS") object["name"] = "u";
        else if (element.nodeName === "DEL") object["name"] = "S";
        else if (element.nodeName !== "BODY" && element.nodeName !== "#text")
          object["name"] = element.nodeName;
      }

      if (typeof object["name"] !== "undefined")
        object["name"] = object["name"].toLowerCase();

      var nodeList = element.childNodes;
      if (nodeList != null) {
        if (nodeList.length) {
          object["children"] = [];
          for (var i = 0; i < nodeList.length; i++) {
            if (origin === "html") {
              if (object["name"] === "span" && !element.nodeValue) {
                object["content"] = nodeList[i].nodeValue;
                continue;
              }
            }

            object["children"].push({});
            treeHTML(
              nodeList[i],
              object["children"][object["children"].length - 1]
            );
            // }
          }
        }
      }
      if (element.attributes != null) {
        // console.log("ATTRS", element.attributes)
        if (element.attributes.length) {
          object["attrs"] = {};
          object["styles"] = {};
          for (var i = 0; i < element.attributes.length; i++) {
            let escapedValue = element.attributes[i].nodeValue;
            // if(escapedValue === "Título"){
            //   console.log(object["name"], element.nodeValue, element.childNodes)
            // }
            if (element.attributes[i].nodeName == "style")
              object["styles"] = inlineStyle2Json(escapedValue);
            else {
              if (element.attributes[i].nodeName === "data-name")
                console.log(object["name"]); //element.attributes[i].nodeName
              object["attrs"][element.attributes[i].nodeName] = escapedValue;
            }
          }
        }
      }
      if (object["name"] === "p") {
        if (typeof object["styles"] === "undefined") object["styles"] = {};
        if (typeof object["styles"]["marginTop"] === "undefined")
          object.styles["marginTop"] = "0";
        if (typeof object["styles"]["marginBottom"] === "undefined")
          object.styles["marginBottom"] = "0";
      }

      // if (element.style != null)
      //   object["styles"] = element.style

      if (
        (object["name"] === "span" && origin != "html") ||
        object["name"] == "a"
      )
        object["content"] = element.nodeValue;
    }
    treeHTML(element[0], treeObject);

    return json ? JSON.stringify(treeObject) : treeObject;
  }

  json = mapDOM(htmlInput, true, origin);
  html = JSON.parse(json);

  const res = /*await*/ iterateChildren(html);
  //  console.log(res)
  return res;
}

function camelCasetoKebab(string) {
  return string.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, "$1-$2").toLowerCase();
}

export function toHtmlString(htmlNodeTree) {
  const iterateChildren = (htmlNode) => {
    let html = `<${htmlNode.name} `;
    const { attrs, styles } = htmlNode;

    if (typeof attrs !== "undefined") {
      for (let [key, value] of Object.entries(attrs)) {
        // console.log(key + ' ' + value);
        html += `${key}="${value}" `;
      }
    }

    if (typeof styles !== "undefined") {
      html += ' style="';
      for (let [key, value] of Object.entries(styles)) {
        // console.log(key + ' ' + value);
        html += `${key}:${value};`;
      }
      html += '"';
    }

    if (
      (htmlNode.hasOwnProperty("children") && htmlNode.children.length > 0) ||
      (htmlNode.content &&
        typeof htmlNode.content !== "undefined" &&
        htmlNode.content != "")
    )
      html += `>`;

    if (
      htmlNode.content &&
      typeof htmlNode.content !== "undefined" &&
      htmlNode.content != ""
    ) {
      html += htmlNode.content;
    }

    let children = [];
    if (htmlNode.hasOwnProperty("children") && htmlNode.children.length > 0) {
      for (let child of htmlNode.children) {
        let childElement = /*await*/ iterateChildren(child);
        children.push(childElement);
      }
    }

    for (let child of children) {
      html += child;
    }

    if (
      children.length > 0 ||
      (typeof htmlNode.content !== "undefined" && htmlNode.content != "")
    )
      html += `</${htmlNode.name}>`;
    else html += ` />`;

    return html;
  };

  return /*await*/ iterateChildren(htmlNodeTree);
}

export function getRootTemplate() {
  const template = {
    html: {
      _id: makeid(),
      name: "html",
      attrs: { xmlns: "http://www.w3.org/1999/xhtml" },
      children: [
        {
          _id: makeid(),
          name: "head",
          children: [
            {
              _id: makeid(),
              name: "meta",
              attrs: {
                charset: "utf-8",
              },
            },
          ],
        },
        {
          _id: makeid(),
          name: "body",
          children: [
            {
              _id: makeid(),
              name: "center",
              children: [
                {
                  _id: makeid(),
                  name: "table",
                  attrs: {
                    border: "0",
                    width: "600",
                    cellspacing: "0",
                    cellpadding: "0",
                    align: "center",
                  },
                  styles: {
                    borderCollapse: "collapse",
                    backgroundColor: "#ffffff",
                  },
                  children: [
                    {
                      _id: makeid(),
                      name: "tbody",
                      children: [
                        {
                          _id: makeid(),
                          name: "tr",
                          children: [
                            {
                              _id: makeid(),
                              name: "td",
                              attrs: { width: "100%", "vertical-align" : "middle", "align" : "center", "data-apiproperty": ""  },
                              styles: {
                                // padding: "2.5%",
                                padding: "0",
                                "background-color": "#ffffff",
                                "opacity": "1",
                                "border-style": "solid",
                                "border-color": "#000000",
                                // "border-width": "1px",
                                "border-width": "0",
                                "text-align": "center",
                                "position": "relative"
                              },
                              // children: [getAddOn("add")],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  };
  return template;
}
