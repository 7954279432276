import React, { Component } from "react";
import auth from "../../services/authService";
import { Link } from "react-router-dom";
import Table from "../common/table";

class TemplatesTable extends Component {
  columns = [
    {
      path: "name",
      label: "Nombre",
      content: template => <Link to={`/templates/${template._id}`}>{template.name}</Link>
    },
   
  ];

  deleteColumn = {
    key: "delete",
    content: template => (
      <div className="float-right">
        <button
          onClick={() => this.props.onDownload(template)}
          className="btn btn-success btn-sm"
        >
          Descargar
        </button>
      <button
        onClick={() => this.props.onDelete(template)}
        className="btn btn-danger btn-sm ml-2"
      >
        Borrar
      </button>
      </div>
      
    )
  };

  constructor() {
    super();
    const user = auth.getCurrentUser();
    if (user && user.isAdmin) this.columns.push(this.deleteColumn);
  }

  render() {
    const { templates, onSort, sortColumn } = this.props;

    return (
      <Table
        columns={this.columns}
        data={templates}
        sortColumn={sortColumn}
        onSort={onSort}
      />
    );
  }
}

export default TemplatesTable;
