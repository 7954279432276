import React from "react"
import { Redirect } from "react-router-dom"
import Joi from "joi-browser"
import Form from "../common/form"
import auth from "../../services/authService"
import Container from "../common/container"
import "./styles.css"

class Login extends Form {

  state = {
    data: { email: "", password: "" },
    errors: {}
  };

  schema = {
    email: Joi.string()
      .required()
      .email()
      .label("Email"),
    password: Joi.string()
      .required()
      .label("Password")
  }

  doSubmit = async () => {
    try {
      const { data } = this.state
      await auth.login(data.email, data.password)

      const { state } = this.props.location
      window.location = state ? state.from.pathname : "/home"
    } catch (ex) {
      if (ex.response && ex.response.status ===400) {
        const errors = { ...this.state.errors }
        errors.email = ex.response.data
        this.setState({ errors })
      }
    }
  }

  render() {

    if (auth.getCurrentUser()) return <Redirect to="/home" />;

    return (

    <Container>
      <div className="row mt-5">
        <div className="col-md-6 offset-md-3">
          <form onSubmit={this.handleSubmit}>
          {this.renderInput("email", "Email", "Email")}
          {this.renderInput("password", "Password", "password")}
          {this.renderButton("Login")}
        </form>
        </div>
      </div>
    </Container>
  )
     }
}

export default Login


