import http from "./httpService";
import { apiUrl } from "../config.json";

const apiEndpoint = apiUrl + "/subscriptions";


function subscriptionUrl(id) {
    return `${apiEndpoint}/${id}`;
  }

export function getSubscriptions() {
  return http.get(`${apiEndpoint}`);
}

export function getSubscriptionsByProgramId(programId) {
  return http.get(`${apiEndpoint}/by-program/${programId}`);
}

export function getSubscriptionsByUserId(userId) {
  return http.get(`${apiEndpoint}/by-user/${userId}`);
}

export function getSubscription(id) {
  return http.get(subscriptionUrl(id));
}

export function saveSubscription(subscription) {
  const body = { ...subscription };
  delete body.userName;
  delete body.userLastName;
  console.log(body)
  if (subscription._id) {
    
    delete body._id;
    return http.put(subscriptionUrl(subscription._id), body);
  }

  return http.post(`${apiEndpoint}`, body);
}

export function deleteSubscription(id) {
  return http.delete(subscriptionUrl(id));
}
