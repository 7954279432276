import React from "react";
import Joi from "joi-browser";
import Form from "../common/form";
import { toast } from "react-toastify";
import { getMailing, saveMailing } from "../../services/mailingService";
import { getCategoriesByProgramId } from "../../services/categoryService";
import { getPrograms } from "../../services/programService";
import {
  getWeekDays,
  getMonthDays,
  getMonthNumbers,
} from "../../services/dateService";
import { data } from "jquery";

class MailingForm extends Form {
  state = {
    data: {
      name: "",
      isRecurring: 'false',
      weekDay: 0,
      recurringType: "",
      year: 1,
      week: 1,
      month: 1,
      day: 1,
      programId: "",
      categories: [],
    },
    recurringTypes: [
      {_id: 'weekly', name: 'Semanal'},
      {_id: 'monthly', name: 'Monthly'}
    ],
    programs: [],
    categories: [],
    weekDays: [],
    months: [],
    monthDays: [],
    errors: {},
  };

  schema = {
    _id: Joi.string(),
    name: Joi.string().required().label("Nombre"),
    isRecurring: Joi.string().required().label("Es recurrente"),
    weekDay: Joi.number().label("Día de la semana"),
    year: Joi.number().label("Año"),
    month: Joi.number().label("Mes"),
    week: Joi.number().label("Semana"),
    day: Joi.number().label("Día"),
    programId: Joi.string().required().label("Programa"),
    categories: Joi.array().label("Las categorías"),
    recurringType: Joi.string().label("Intervalo").allow("")
  };

  async populateDateData() {
    const weekDays = await getWeekDays();
    this.setState({ weekDays: weekDays });
    const months = await getMonthNumbers();
    this.setState({ months: months });
    const monthDays = await getMonthDays();
    this.setState({ monthDays: monthDays });
  }

  async populatePrograms() {
    const { data } = await getPrograms();
    console.log(data.programs);
    this.setState({ programs: data.programs });
  }

  async populateCategories() {
    const { data } = await getCategoriesByProgramId(this.state.data.programId);
    console.log(data.userCategories);
    this.setState({ categories: data.userCategories });
  }

  async populateMailing() {
    try {
      const mailingId = this.props.match.params.id;
      if (mailingId ==="new") return;

      const res = await getMailing(mailingId);
      const mailing = res.data.mailing;
      this.setState({ data: this.mapToViewModel(mailing) });
    } catch (ex) {
      if (ex.response && ex.response.status ===404)
        this.props.history.replace("/not-found");
    }
  }

 setWeekDay = () => {
   if(this.state.data.weekDay == undefined) {
    const {data} = this.state
    data.weekDay = 0
    this.setState({ data });

   }
 }

  async componentDidMount() {
    await this.populatePrograms();
    await this.populateDateData();
    await this.populateMailing();
    await this.setWeekDay();
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.data.programId !==this.state.data.programId)
      await this.populateCategories();
  }

  mapToViewModel(mailing) {
    return {
      _id: mailing._id,
      name: mailing.name,
      programId: mailing.program._id,
      isRecurring: mailing.isRecurring.toString(),
      weekDay: mailing.weekDay,
      year: mailing.year,
      month: mailing.month,
      week: mailing.week,
      day: mailing.day,
      categories: mailing.userCategories,
      recurringType: mailing.recurringType
    };
  }


  doSubmit = async () => {

    try {
      const {data} = this.state
      const categories = data.categories.map((cat) => cat._id)
      data.categories = categories
      this.setState({data})
      await saveMailing(this.state.data);

      this.props.history.push("/mailings");

    }catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data);
      }
    }
  };

  handleChangeMultiple = ({ currentTarget: input }) => {
    const exists = this.state.data.categories.find(
      (cat) => cat._id ===input.value
    );
    if (typeof exists !=="undefined") return;

    const userCategory = this.state.categories.filter(
      (cat) => cat._id ===input.value
    );
    const data = { ...this.state.data };
    const originalCategories = data[input.name];

    const newCategories = originalCategories.concat(userCategory);
    data[input.name] = newCategories;
    this.setState({ data });
  };

  handleClickBadge = (categoryId) => {
    const newcategories = this.state.data.categories.filter(
      (cat) => cat._id !==categoryId
    );
    const data = { ...this.state.data };
    data.categories = newcategories;
    this.setState({ data });
  };

  render() {
    const mailingId = this.props.match.params.id;
    return (
      <div>
        {mailingId ==="new" && <h2>Crear Mailing</h2>}
        {mailingId !=="new" && <h2>Editar Mailing</h2>}
        <form onSubmit={this.handleSubmit}>
          {this.renderInput("name", "Nombre")}
          {this.renderInput("isRecurring", "Es recurrente", "checkbox")}
          {this.state.data.isRecurring === "true" && this.renderSelect("recurringType", "Frecuencia", this.state.recurringTypes)}
          {this.state.data.isRecurring === "true" && this.state.data.recurringType === "weekly" &&
            this.renderSelect(
              "weekDay",
              "El día de la semana",
              this.state.weekDays
            )}
            {
              this.state.data.isRecurring === "false"  && 
              <React.Fragment>
                {this.renderInput("year", "Año")}
                {this.renderInput("month", "Mes")}
                {this.renderInput("week", "Semana")}
                {this.renderInput("day", "Día")}
              </React.Fragment>
            }
          {/* {this.state.data.isRecurring ===false &&
            this.renderSelect("month", "Mes", this.state.months)}
          {this.state.data.isRecurring ===false &&
            this.renderSelect("day", "Día", this.state.monthDays)} */}
          {this.renderSelect("programId", "Programa", this.state.programs)}
          {this.state.categories.length > 0 && (
            <div className="form-group">
              <label>Categorías</label>
              <select
                name="categories"
                onChange={this.handleChangeMultiple}
                className="form-control"
                multiple
              >
                <option value="" />
                {this.state.categories.map((option) => (
                  <option key={option._id} value={option._id}>
                    {option.name}
                  </option>
                ))}
              </select>
              <div className="mt-2">
                {this.state.data.categories.map((cat) => (
                  <span
                    onClick={() => this.handleClickBadge(cat._id)}
                    className="badge badge-secondary p-2 mr-2"
                    key={cat._id}
                  >
                    {cat.name} X
                  </span>
                ))}
              </div>
            </div>
          )}
          {this.renderButton("Guardar")}
        </form>
      </div>
    );
  }
}

export default MailingForm;
