import React from "react";
const ImportFromFile = (props) => {
  const { getContent } = props;
  let fileReader;

  const handleFileRead = (e) => {
    const content = fileReader.result;
    getContent(content);
  };

  const handleFileChosen = (file) => {
    fileReader = new FileReader();
    fileReader.onloadend = handleFileRead;
    fileReader.readAsText(file);
  };

  return (
    <div className="upload-expensa">
      <input
        type="file"
        id="file"
        className="input-file"
        accept=".html"
        onChange={(e) => handleFileChosen(e.target.files[0])}
      />
    </div>
  );
};

export default ImportFromFile;
