import React from "react";

const Input = ({ name, label, type, error, value, ...rest }) => {
  return (
    <div className="form-group">
      {type != "hidden" && <label htmlFor={name}>{label}</label>}
      {type === "checkbox" && value === "true" && (
        <input
          checked
          type={type}
          value={value}
          {...rest}
          name={name}
          id={name}
          className="form-control"
        />
      )}
      {(type !== "checkbox" || (type === "checkbox" && value !== "true")) && (
        <input
          type={type}
          value={value}
          {...rest}
          name={name}
          id={name}
          className="form-control"
        />
      )}
      {error && <div className="alert alert-danger">{error}</div>}
    </div>
  );
};

export default Input;
