import React from "react";
import Joi from "joi-browser";
import Form from "../common/form";
import { getCategory, saveCategory } from "../../services/categoryService";
import { getPrograms } from "../../services/programService";
import {getMailingNumbersByUserCategory} from '../../services/mailingNumberService'
import NumbersTable from "./numbersTable";

class CategoryForm extends Form {
  state = {
    data: {
      name: "",
      programId: "",
    },
    programs: [],
    numbers: [],
    errors: {}
  };

  schema = {
    _id: Joi.string(),
    name: Joi.string()
      .required()
      .label("Nombre"),
    programId: Joi.string()
      .required()
      .label("Programa")
  };

  async populatePrograms() {
    const { data } = await getPrograms();
    console.log(data.programs)
    this.setState({ programs: data.programs });
  }

  async populateCategory() {
    try {
      const categoryId = this.props.match.params.id;
      if (categoryId ==="new") return;

      const res = await getCategory(categoryId);
      const userCategory = res.data.userCategory
      console.log(res)
      this.setState({ data: this.mapToViewModel(userCategory) });
    } catch (ex) {
      if (ex.response && ex.response.status ===404)
        this.props.history.replace("/not-found");
    }
  }

  async populateMailingNumbers() {
    const { data } = await getMailingNumbersByUserCategory(this.props.match.params.id);
    this.setState({ numbers: data.mailingNumbers });
  }

  async componentDidMount() {
    await this.populatePrograms();
    await this.populateCategory();
    if(this.props.match.params.id !== 'new')
      await this.populateMailingNumbers()
  }

  mapToViewModel(category) {
    return {
      _id: category._id,
      name: category.name,
      programId: category.program._id
    };
  }

  doSubmit = async () => {
    await saveCategory(this.state.data);

    this.props.history.push("/categories");
  };
  
  handleSort = (sortColumn) => {
    this.setState({ sortColumn });
  };

  render() {
    const categoryId = this.props.match.params.id
    return (
      <div>
        {categoryId ==='new' && <h2>Crear Categoría</h2>}
        {categoryId !=='new' && <h2>Editar Categoría</h2>}
        <form onSubmit={this.handleSubmit}>
          {this.renderInput("name", "Nombre")}
          {this.renderSelect("programId", "Programa", this.state.programs)}
          {this.renderButton("Save")}
        </form>
        {this.state.numbers && this.state.numbers.length > 0 && <div className="mt-2">
          <h3 id="program">Envíos programados</h3>
          <NumbersTable
              numbers={this.state.numbers}
              sortColumn={{ path: "absoluteNumber", order: "asc" }}
              // onDelete={this.handleDelete}
              onSort={this.handleSort}
            />
          </div>}
      </div>
    );
  }
}

export default CategoryForm;
