import React, { Component } from "react";
import EmailsTable from "./table";
import Pagination from "../common/pagination";
import { getEmails } from "../../services/emailService";
import { paginate } from "../../utils/paginate";
import _ from "lodash";
import SearchBox from "../searchBox";

class Emails extends Component {
  state = {
    emails: [],
    pageSize: 10,
    searchQuery: "",
    sortColumn: { path: "createdAt", order: "asc" },
    currentPage: 1,
    itemsCount: 1,
    loading: true,
  };

  componentDidMount() {
    this.originalPopulation()
  }

  originalPopulation = async () => {
    const { data } = await getEmails(
      1,
      ""
    );
    this.setState({
      emails: data.emails,
      itemsCount: data.count,
      loading: false,
    });
  }

  handlePageChange = async (page) => {
    this.setState({ loading: true });
    const { data } = await getEmails(
      this.state.currentPage + 1,
      this.state.searchQuery
    );
    this.setState({
      currentPage: page,
      emails: data.emails,
      loading: false,
      itemsCount: data.count,
    });
  };

  handleSearch = async (query) => {
    this.setState({ searchQuery: query });
    if(query.trim() === ""){
      this.originalPopulation()
    }
    if (query.length < 4) return;
    this.setState({ currentPage: 1, loading: true });
    const { data } = await getEmails(
      this.state.currentPage,
      query
    );
    this.setState({
      emails: data.emails,
      loading: false,
      itemsCount: data.count,
    });
  };

  handleSort = (sortColumn) => {
    this.setState({ sortColumn });
  };

  render() {
    const { length: count } = this.state.emails;
    const {
      loading,
      pageSize,
      currentPage,
      sortColumn,
      searchQuery,
      emails,
      itemsCount,
    } = this.state;

    // const { data: emails } = this.getPagedData();

    return (
      <div className="row">
        <div className="col">
          <SearchBox value={searchQuery} onChange={this.handleSearch} />
          <p>Mostrando {itemsCount} registros.</p>
          {count > 0 && (
            <React.Fragment>
              {!loading && (
                <EmailsTable
                  emails={emails}
                  sortColumn={sortColumn}
                  onDelete={this.handleDelete}
                  onSort={this.handleSort}
                />
              )}
              <Pagination
                itemsCount={itemsCount}
                pageSize={pageSize}
                currentPage={currentPage}
                onPageChange={this.handlePageChange}
              />
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}

export default Emails;
