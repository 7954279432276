import React from "react";
import "./styles.css";
import { useState } from "react";
import ImageUpload from "../imageUpload";
import { useEffect } from "react";
import TextEditor from "../common/textEditor";
import { toHtmlString } from "../../services/templateService";
import ApiDataFetcher from "../ApiDataFetcher";
import { getPrograms, getProgram } from "../../services/programService";

const Preview = (props) => {
  const { handleHtmlChange, editable, selectedProgram } = props;
  const [selectedElement, setSelectedElement] = useState(null);
  const [showTr, setShowTr] = useState(false);
  const [isClicked, setClick] = useState(0);
  const [programs, setPrograms] = useState([{name:"Programa", _id:"Id"}]);
  const [apiUrl, setApiUrl] = useState("");


  const [editableMode, setEditableMode] = useState("content");
  let editableElements = [];

  const mapElement = (element) => {
    if (typeof element === "undefined") return "div";
    element = element.toLowerCase();
    // console.log(element)
    switch (element) {
      case "html":
        return "div";
      case "head":
        return "div";
      case "meta":
        return "meta";
      case "link":
        return "link";
      case "body":
        return "div";

      case "center":
        return "center";
      case "table":
        return "table";
      case "tbody":
        return "tbody";
      case "thead":
        return "thead";
      case "tr":
        return "tr";
      case "td":
        return "td";
      case "img":
        return "img";
      case "span":
        return "span";
      case "p":
        return "p";
      case "a":
        return "a";
      case "br":
        return "br";
      case "em":
        return "em";
      case "u":
        return "u";
      case "s":
        return "s";
      case "code":
        return "code";
      case "sup":
        return "sup";
      case "strong":
        return "strong";
      case "ol":
        return "ol";
      case "ul":
        return "ul";
      case "li":
        return "li";
      default:
        return element;
    }
  };

  const camelize = (str) => {
    if (!str.includes("-")) return str;

    let arr = str.split("-");
    let capital = arr.map((item, index) =>
      index ? item.charAt(0).toUpperCase() + item.slice(1).toLowerCase() : item
    );

    let capitalString = capital.join("");

    return capitalString;
  };

  useEffect(() => {
    async function populateProgram() {
      const { data } = await getPrograms();

    const apiUrlPrograms = []
      for(let program of data.programs){

        if(program.apiUrl === ""){
          continue
        } else {
          apiUrlPrograms.push(program)
        }

      }

      setPrograms(apiUrlPrograms)

      
    }
    populateProgram();
  }, []);



  const mapAttr = (name) => {
    switch (name) {
      case "charset":
        return "charSet";
      case "cellspacing":
        return "cellSpacing";
      case "cellpadding":
        return "cellPadding";
      case "colspan":
        return "colSpan";
      default:
        return name;
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const iterateChildren = (htmlNode, wysiwygParent = null) => {
    let attrs = {
       onClick: (e) => handleClick(e, wysiwygParent ? wysiwygParent : htmlNode),
    };
    if (
      typeof htmlNode === "undefined" /*|| mapElement(htmlNode.name) === null*/
    )
      return null;
    if (typeof htmlNode.attrs !== "undefined") {
      for (const [key, value] of Object.entries(htmlNode.attrs)) {
        attrs[mapAttr(key)] = value;
        if (key === "data-name") {
          editableElements.push(htmlNode);
        }
      }
    }
    if (htmlNode.name === "a") htmlNode.attrs.target = "_blank";
    if (htmlNode.name === "tr") {
      if (showTr) attrs.className = "tr-highlighted";
      else attrs.className = "";
    }
    if (typeof htmlNode.styles !== "undefined") {
      attrs.style = {};
      for (const [key, value] of Object.entries(htmlNode.styles)) {
        attrs.style[camelize(key)] = value;
      }
      // attrs.style = htmlNode.styles
      // console.log(attrs.style)
    }

    if (htmlNode.name === "p") {
      if (typeof htmlNode.styles === "undefined") {
        attrs.style = {};
      }
      if (typeof attrs.style.marginTop === "undefined") {
        attrs.style.marginTop = 0;
      }
      if (typeof attrs.style.marginBottom === "undefined") {
        attrs.style.marginBottom = 0;
      }
    }

    let content = null;
    if (typeof htmlNode.content !== "undefined") content = htmlNode.content;

    if (
      htmlNode.name === "p" &&
      htmlNode.children.length === 0 &&
      typeof htmlNode.content === "undefined"
    ) {
      // htmlNode.name = 'br'
      htmlNode.children.push({ name: "br" });
    }
    if (
      !htmlNode.hasOwnProperty("children") ||
      htmlNode.children.length === 0
    ) {
      const newReactElement = React.createElement(
        mapElement(htmlNode.name),
        attrs,
        content
      );
      return newReactElement;
    }

    let children = [];
    for (let child of htmlNode.children) {
      if (
        htmlNode.hasOwnProperty("attrs") &&
        typeof htmlNode.attrs !== "undefined" &&
        ((htmlNode.attrs.hasOwnProperty("className") &&
          typeof htmlNode.attrs.className !== "undefined" &&
          htmlNode.attrs.className.includes("wysiwyg")) ||
          ((htmlNode.name === "span" || htmlNode.name === "div") &&
            htmlNode.attrs.hasOwnProperty("data-name") &&
            htmlNode.attrs["data-name"] != ""))
      ) {
        wysiwygParent = htmlNode;
      }
      let childElement = iterateChildren(child, wysiwygParent);
      children.push(childElement);
    }
    const newReactElement = React.createElement(
      mapElement(htmlNode.name),
      attrs,
      ...children,
      content
    );
    return newReactElement;
  };

  const printTree = (rootNode) => {
    editableElements = [];
    const res = iterateChildren(rootNode);
    return <div id="preview">{res}</div>;
  };

  const printAttrs = (element) => {
    return Object.entries(element.attrs).map(([key, value]) => {
      if (key === "className") return;
      return (
        <div className="form-group" key={element + "-" + key}>
          <label>{key}</label>
          <input
            onChange={(e) => handleChange(element, "attrs", e)}
            type="text"
            name={key}
            className="form-control"
            value={value}
          />
        </div>
      );
    });
  };

  const printStyles = (element) => {
    return Object.entries(element.styles).map(([key, value]) => {
      let type = "text";
      if (key === "color") type = "color";
      return (
        <div className="form-group" key={element + "-" + key}>
          <label>{key}</label>
          <input
            onChange={(e) => handleChange(element, "styles", e)}
            type={type}
            name={key}
            className="form-control"
            value={value}
          />
        </div>
      );
    });
  };

  const printContent = (element) => {
    return (
      <div className="form-group">
        <label>Texto</label>
        <TextEditor />
      </div>
    );
  };

  const handleChange = (element, type, { currentTarget: input }) => {
    handleHtmlChange("edit", element._id, {
      type,
      name: input.name,
      value: input.value,
    });
  };



  const handleClick = (e, htmlNode) => {
    if (!editable) return;
    e.stopPropagation();
    // console.log(htmlNode);
    if (selectedElement){
      if(selectedElement._id !== htmlNode._id){
        unselectElement(e)
        setTimeout(() => {
          // if (
          //   htmlNode.hasOwnProperty("attrs") &&
          //   typeof htmlNode.attrs !== "undefined" &&
          //   htmlNode.attrs.hasOwnProperty("data-name") &&
          //   htmlNode.attrs["data-name"] != ""
          // )
          //   selectElement(htmlNode);
          selectElement(htmlNode);
          if(document.getElementById(htmlNode._id) != null) {
            let divElement = document.getElementById(htmlNode._id)
            divElement.scrollIntoView({behavior: "smooth"});
          } else {
            return
          }
          
          var elems = document.querySelectorAll(".card-body");

          [].forEach.call(elems, function(el) {
          el.classList.remove("Animation");
          });

          let idOfSelectedElement = document.getElementById(htmlNode._id)
          idOfSelectedElement.classList.add("Animation")
          
        }, 300);
      }
    }else{
      // if (
      //   htmlNode.hasOwnProperty("attrs") &&
      //   typeof htmlNode.attrs !== "undefined" &&
      //   htmlNode.attrs.hasOwnProperty("data-name") &&
      //   htmlNode.attrs["data-name"] != ""
      // )
        
        selectElement(htmlNode);
        if(document.getElementById(htmlNode._id) != null) {
          let divElement = document.getElementById(htmlNode._id)
          divElement.scrollIntoView({behavior: "smooth"});
        } else {
          return
        };

          var idOfSelectedElement = document.getElementById(htmlNode._id)
          idOfSelectedElement.classList.add("Animation")
    }
      
     
     if (selectedElement) {
      unselectElement(e);
      var elems = document.querySelectorAll(".card-body");

          [].forEach.call(elems, function(el) {
          el.classList.remove("Animation");
          });
     } 
    
  };

  const selectElement = (htmlNode) => {
    if (selectedElement) handleHtmlChange("unselect", selectedElement._id);
    setSelectedElement(htmlNode);
    handleHtmlChange("select", htmlNode._id);
  };

  const unselectElement = (e) => {
    e.stopPropagation();
    console.log("unselect!")
    handleHtmlChange("unselect", selectedElement._id);
    setSelectedElement(null);
  };

  

  const copyLine = (e, element) => {
    e.stopPropagation();
    handleHtmlChange("copyTr", element._id);
  };

  const addNewLine = (e, element) => {
    e.stopPropagation();
    handleHtmlChange("addNewLine", element._id);
  };

  const removeLine = (e, element) => {
    e.stopPropagation();
    handleHtmlChange("removeTr", element._id);
  };

  const removeElement = (e, element) => {
    e.stopPropagation();
    handleHtmlChange("removeElement", element._id);
  };

  const toogleTr = (e) => {
    e.stopPropagation();
    setShowTr(!showTr);
    // handleHtmlChange("highlightTr", showTr)
  };

  const changeImage = (element, src) => {
    handleHtmlChange("changeImage", element._id, { src });
  };

  const changeBgImage = (element, src) => {
    handleHtmlChange("changeBgImage", element._id, { src });
  };

  const addSpan = (e, element) => {
    e.stopPropagation();
    handleHtmlChange("addSpan", element._id);
  };

  const transformToAnchor = (e, element) => {
    e.stopPropagation();
    handleHtmlChange("transformToAnchor", element._id);
  };

  const printTextContent = (htmlNode) => {
    const htmlString = toHtmlString(htmlNode);
    return (
      <div className="form-group">
        <TextEditor
          elementId={htmlNode._id}
          initialHtml={htmlString}
          onTextChange={onTextChange}
        />
      </div>
    );
  };

  const printAnchorContent = (htmlNode) => {
    return (
      <div className="form-group">
        <label>Link</label>
        <input
          onChange={(e) => handleChange(htmlNode, "attrs", e)}
          type="text"
          name="href"
          className="form-control"
          value={htmlNode.attrs.href}
          autoFocus
        />
      </div>
    );
  };

  const changeImageFromContent = (element, src) => {
    // setContentNodeAsSelected(htmlNode)
    handleHtmlChange("changeImage", element._id, { src });
  };

  const onTextChange = (content, elementId) => {
    // console.log(content);
    handleHtmlChange("editWysiwyg", elementId, {
      type: "content",
      value: content,
    });
  };

  const updateContentFromApi = (value, element) => {
    console.log(value, element);
    if (element.name === "img") {
      handleHtmlChange("changeImage", element._id, { src: value });
    } else if (element.name === "td") {
      handleHtmlChange("changeBgImage", element._id, { src: value });
    } else if (element.name === "a") {
      handleHtmlChange("changeHref", element._id, { href: value });
    } else {
      handleHtmlChange("edit", element._id, {
        type: "content",
        name: element.attrs["data-apiproperty"],
        value,
      });
    }
  };

  const programHandleChange = async ({ currentTarget: input }) => {

    console.log("INPUT", input.value)

    const program = await getProgram(input.value)
    console.log("PROGRAMA SELECIONADO", program.data.program.apiUrl)
    setApiUrl(program.data.program.apiUrl)

  }

  const printContentCard = (htmlNode) => {
     // console.log("printContentCard",htmlNode)
    return (
      <div className="card-body" id={htmlNode._id}>
        <div className="row">
          <div className="col-12">
            {htmlNode.attrs?.["data-apiproperty"] && (
              <button onClick={() => setClick(isClicked + 1)} type="button" className="btn btn-primary">Obtener contenido por API</button> 
            )}
            <h5 className="card-title">
              Nombre:{" "}
              {htmlNode.attrs?.["data-name"] ?? htmlNode.attrs["data-name"]}
            </h5>
            { isClicked >= 1 && (
              <div>
              <label>Programas</label>
              <select onChange={programHandleChange} name="programId" id="programId" className="form-control">
                <option value="" />
                {programs.map(program => (
                  <option key={program._id} value={program._id}>
                    {program.name}
                  </option>
                ))}
              </select>
              </div>
              )}

            {apiUrl != "" &&  htmlNode.attrs?.["data-apiproperty"] && (
              <ApiDataFetcher
                updateContent={() => updateContentFromApi(htmlNode)}
                apiUrl={apiUrl}
                apiParam={htmlNode.attrs["data-apiproperty"]}
              />
            )}
          </div>
        </div>

        <div className="row mt-2">
          <div className="col">
            {htmlNode.name === "img" && (
              <ImageUpload
                aspect={{
                  x: htmlNode.attrs.width,
                  y: htmlNode.attrs.height,
                }}
                changeImage={(e) => {
                  changeImageFromContent(htmlNode, e);
                }}
              />
            )}
            {htmlNode.name === "td" && htmlNode.attrs.background && (
              <div className="row mt-2">
                <div className="col">
                  <h6>Imagen de fondo</h6>
                  <ImageUpload
                    aspect={{
                      x: htmlNode.attrs.width,
                      y: htmlNode.attrs.height,
                    }}
                    changeImage={changeBgImage}
                  />
                </div>
              </div>
            )}
            {htmlNode.name === "a" && (
              <div className="row mt-2">
                <div className="col">{printAnchorContent(htmlNode)}</div>
              </div>
            )}
          </div>
        </div>

        <ul className="list-group list-group-flush">
          {typeof htmlNode.content !== "undefined" && (
            <li className="list-group-item">
              {(htmlNode.hasOwnProperty("content") ||
                (htmlNode.hasOwnProperty("attrs") &&
                  htmlNode.attrs.hasOwnProperty("className") &&
                  htmlNode.attrs.className.includes("wysiwyg"))) &&
                printTextContent(htmlNode)}
            </li>
          )}
        </ul>
      </div>
    );
  };

  const printContentTree = () => {
    // const res = iterateChildrenForContent(rootNode);
    return (
      <div className="element-cards">
        {editableElements.map((element) => (
          <div key={element._id} className="element-card">
            <div className="card">{printContentCard(element)}</div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="row">
      <div className={editable ? `col-md-7` : `col`}>{printTree(props.html)}</div>
      {editable && (
        <div className="col-md-5 p-0">
        {editableMode === "inspector" &&
          editableElements.map((element) => (
            <div className="card element-card">
              <div className="card-body">
                <div className="row">
                  <div className="col-2">
                    <h5 className="card-title">{element.name}</h5>
                  </div>
                  {element.name === "td" && (
                    <React.Fragment>
                      <div className="col-3 p-0">
                        <button
                          type="button"
                          onClick={() => copyLine(element)}
                          className="btn btn-outline-primary"
                        >
                          Copiar linea
                        </button>
                      </div>
                      <div className="col-3 p-0">
                        <button
                          onClick={() => removeLine(element)}
                          type="button"
                          className="btn btn-outline-primary"
                        >
                          Borrar linea
                        </button>
                      </div>
                    </React.Fragment>
                  )}
                  {element.name === "td" && (
                    <div className="col-3 p-0">
                      <button
                        type="button"
                        onClick={() => removeElement(element)}
                        className="btn btn-outline-primary"
                      >
                        Borrar
                      </button>
                    </div>
                  )}
                  {element.name === "span" && (
                    <div className="col-3 p-0">
                      <button
                        type="button"
                        onClick={() => transformToAnchor(element)}
                        className="btn btn-outline-primary"
                      >
                        Hacer link
                      </button>
                    </div>
                  )}
                </div>

                {element.name === "img" && (
                  <div className="row mt-2">
                    <div className="col">
                      <ImageUpload
                        aspect={{
                          x: element.attrs.width,
                          y: element.attrs.height,
                        }}
                        changeImage={() => changeImage(element)}
                      />
                    </div>
                  </div>
                )}

                <ul className="list-group list-group-flush">
                  {typeof element.content !== "undefined" && (
                    <li className="list-group-item">
                      <h6>Contenido</h6>
                      {printContent(element)}
                      <button
                        type="button"
                        onClick={() => addNewLine(element)}
                        className="btn btn-outline-primary"
                      >
                        Nueva línea
                      </button>
                      <button
                        type="button"
                        onClick={() => addSpan(element)}
                        className="btn btn-outline-primary"
                      >
                        Añadir texto
                      </button>
                    </li>
                  )}

                  {typeof element.styles !== "undefined" && (
                    <li className="list-group-item">
                      <h6>Estilos</h6>
                      {printStyles(element)}
                    </li>
                  )}

                  {typeof element.attrs !== "undefined" && (
                    <li className="list-group-item">
                      <h6>Atributos</h6>
                      {printAttrs(element)}
                    </li>
                  )}
                </ul>
              </div>
            </div>
          ))}
        {editableMode === "content" && printContentTree()}
      {/* <button
          type="button"
          onClick={toogleTr}
          className="btn btn-outline-primary"
          id="toogle-tr-button"
        >
          Grilla
        </button>
      */} 
      </div>
      )}
      
    </div>
  );
};

export default Preview;
