import http from "./httpService";
import { apiUrl } from "../config.json";

const apiEndpoint = apiUrl + "/mailing-numbers";

function mailingNumberUrl(id) {
  return `${apiEndpoint}/${id}`;
}

export function getMailingNumberByPieceId(pieceId) {
  return http.get(`${apiEndpoint}/by-piece-id/${pieceId}`);
}

export function getMailingNumbersByUserCategory(userCategory) {
  return http.get(`${apiEndpoint}/by-user-category/${userCategory}`);
}

export function getMailingNumber(id) {
  return http.get(mailingNumberUrl(id));
}


export function saveMailingNumber(mailingNumber) {

  const body = { ...mailingNumber };



  if (mailingNumber._id) {
    const body = { ...mailingNumber };
    delete body._id;
  
    return http.put(mailingNumberUrl(mailingNumber._id), body);
  }
  delete body._id;
  return http.post(`${apiEndpoint}`, body);
}

export function mailingNumberDelete(id) {
  return http.delete(mailingNumberUrl(id));
}

export function sendTest(body){
  return http.post(`${apiEndpoint}/send-test`, body)
}

export async function downloadMailingNumber(mailingNumber) {
  const response = await http.get(`${apiEndpoint}/download/${mailingNumber._id}`);
  // console.log(response.data.html)
  const blob = new Blob([response.data.html]);
  let url = window.URL.createObjectURL(blob);
  let a = document.createElement("a");
  a.href = url;
  a.download = `${mailingNumber.number}.html`;
  a.click();
}

export function copyMailingNumber(mailingNumber){
  // let body = mailingNumber
  // // console.log(body)
  // delete body._id;
  // body.programId = body.program._id
  // delete body.program
  // body.mailingId = body.mailing._id
  // delete body.mailing
  return http.post(`${apiEndpoint}/copy`, mailingNumber)
}

