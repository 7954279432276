import React from "react";
import { getRecentEmails } from "../../services/emailService";
import { useEffect } from "react";
import { useState } from "react";

const Home = () => {
  const [weeklyEmails, setWeeklyEmails] = useState({
    total: { num: 0, percent: 0 },
    delivered: { num: 0, percent: 0 },
    open: { num: 0, percent: 0 },
    clicked: { num: 0, percent: 0 },
    bounced: { num: 0, percent: 0 },
  });
  const [monthlyEmails, setMonthlyEmails] = useState({
    total: { num: 0, percent: 0 },
    delivered: { num: 0, percent: 0 },
    open: { num: 0, percent: 0 },
    clicked: { num: 0, percent: 0 },
    bounced: { num: 0, percent: 0 },
  });
  const [showingEmails, setShowingEmails] = useState({
    total: { num: 0, percent: 0 },
    delivered: { num: 0, percent: 0 },
    open: { num: 0, percent: 0 },
    clicked: { num: 0, percent: 0 },
    bounced: { num: 0, percent: 0 },
  });
  const [period, setPeriod] = useState("weekly");

  useEffect(() => {
      
    const fetchData = async () => {
        const wEmails = await populateEmails("weekly")
         setShowingEmails(wEmails);
        setWeeklyEmails(wEmails)   
        setPeriod("weekly")
        // selectPeriod("weekly")
        const mEmails = await populateEmails("monthly")
        setMonthlyEmails(mEmails) 
      }
    fetchData() 

  }, []);

  


  const populateEmails = async (period) => {
    const res = await getRecentEmails(period);
    const emails = res.data.emails
    const total = { num: emails.length, percent: 100 };
    const deliveredEmails = emails.filter(
      (email) =>
        email.hasOwnProperty("events") &&
        email.events.length &&
        email.events.length > 0 &&
        email.events.find((event) => event.event === "delivered")
    );
    const delivered = { num: deliveredEmails.length, percent: calculatePercentage(total.num, deliveredEmails.length) };
    const openEmails = emails.filter(
        (email) =>
          email.hasOwnProperty("events") &&
          email.events.length &&
          email.events.length > 0 &&
          email.events.find((event) => event.event === "open")
      );
    const open = { num: openEmails.length, percent: calculatePercentage(total.num, openEmails.length) };
    const clickedEmails = emails.filter(
        (email) =>
          email.hasOwnProperty("events") &&
          email.events.length &&
          email.events.length > 0 &&
          email.events.find((event) => event.event === "click")
      );
      const clicked = { num: clickedEmails.length, percent: calculatePercentage(total.num, clickedEmails.length) };
      const bouncedEmails = emails.filter(
        (email) =>
          email.hasOwnProperty("events") &&
          email.events.length &&
          email.events.length > 0 &&
          email.events.find((event) => event.event === "bounce")
      );
      const bounced = { num: bouncedEmails.length, percent: calculatePercentage(total.num, bouncedEmails.length) };
      return {
            total,
            delivered,
            open,
            clicked,
            bounced
          }
        
  };

  const calculatePercentage = (possible, given) => {
    if(given === 0) return 0
    if (isNaN(possible) || isNaN(given)) {
      return "NaN";
    } else {
      return ((given / possible) * 100).toFixed(2);
    }
  };

  const selectPeriod = (period) => {
    setPeriod(period);
    if (period === "weekly") setShowingEmails(weeklyEmails);
    else setShowingEmails(monthlyEmails);
  };

  return (
    <React.Fragment>
      <div className="row">
        <div className="col-sm-6 text-left">
          <h2 className="card-title">Actividad de envíos</h2>
        </div>
        <div className="col-sm-6">
          <div
            className="btn-group btn-group-toggle float-right"
            data-toggle="buttons"
          >
            <label className="btn btn-sm btn-primary btn-simple active" id="0" onClick={() => selectPeriod("weekly")}>
              {period === "weekly" && (
                <input
                  type="radio"
                  name="options"
                  checked
                  onChange={() => selectPeriod("monthly")}
                />
              )}
              {period !== "weekly" && (
                <input
                  type="radio"
                  name="options"
                  onChange={() => selectPeriod("weekly")}
                />
              )}
              <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                Semanal
              </span>
              <span className="d-block d-sm-none">
                <i className="tim-icons icon-single-02"></i>
              </span>
            </label>
            <label className="btn btn-sm btn-primary btn-simple" id="1" onClick={() => selectPeriod("monthly")}>
              {period === "monthly" && (
                <input
                  type="radio"
                  name="options"
                  checked
                  onChange={() => selectPeriod("weekly")}
                />
              )}
              {period !== "monthly" && (
                <input
                  type="radio"
                  name="options"
                  onChange={() => selectPeriod("monthly")}
                />
              )}
              <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                Mensual
              </span>
              <span className="d-block d-sm-none">
                <i className="tim-icons icon-gift-2"></i>
              </span>
            </label>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-2 col-md-6">
          <div className="card card-stats">
            <div className="card-body text-center">
              <div className="stats">Envíos</div>
              <hr />
              <h3 className="card-title">{showingEmails.total.num}</h3>
              <h6>&nbsp;</h6>
            </div>
          </div>
        </div>

        <div className="col-lg-2 col-md-6">
          <div className="card card-stats">
            <div className="card-body text-center">
              <div className="stats">Entregados</div>
              <hr />
              <h3 className="card-title">{showingEmails.delivered.percent}%</h3>
              <h6>{showingEmails.delivered.num}</h6>
            </div>
          </div>
        </div>

        <div className="col-lg-2 col-md-6">
          <div className="card card-stats">
            <div className="card-body text-center">
              <div className="stats">Abiertos</div>
              <hr />
              <h3 className="card-title">{showingEmails.open.percent}%</h3>
              <h6>{showingEmails.open.num}</h6>
            </div>
          </div>
        </div>

        <div className="col-lg-2 col-md-6">
          <div className="card card-stats">
            <div className="card-body text-center">
              <div className="stats">Clickeados</div>
              <hr />
              <h3 className="card-title">{showingEmails.clicked.percent}%</h3>
              <h6>{showingEmails.clicked.num}</h6>
            </div>
          </div>
        </div>

        <div className="col-lg-2 col-md-6">
          <div className="card card-stats">
            <div className="card-body text-center">
              <div className="stats">Rebotados</div>
              <hr />
              <h3 className="card-title">{showingEmails.bounced.percent}%</h3>
              <h6>{showingEmails.bounced.num}</h6>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="row">
        <div className="col">
          <div className="card card-chart">
            <div className="card-body">
              <div className="chart-area">
                <canvas id="chartBig1"></canvas>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </React.Fragment>
  );
};

export default Home;
