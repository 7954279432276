import React, { Component } from "react"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"
import SubscriptionsTable from "./table"
import ListGroup from "../common/listGroup"
import Pagination from "../common/pagination"
import {
  getSubscriptionsByProgramId,
  deleteSubscription,
} from "../../services/subscriptionService"
import { getPrograms } from "../../services/programService"
import { getCategoriesByProgramId } from "../../services/categoryService"
import { paginate } from "../../utils/paginate"
import _ from "lodash"
import SearchBox from "../searchBox"

class Subscriptions extends Component {
  state = {
    subscriptions: [],
    programs: [],
    categories: [],
    currentPage: 1,
    pageSize: 4,
    searchQuery: "",
    selectedProgram: null,
    selectedCategory: null,
    sortColumn: { path: "name", order: "asc" },
  }

  async componentDidMount() {
    const { data } = await getPrograms()
    const programs = [{ _id: "", name: "All Programs" }, ...data.programs]
    // console.log(programs)

    let subscriptions = []
    for (let program of programs) {
      if (program._id !=="") {
        const data = await getSubscriptionsByProgramId(program._id)
        subscriptions = subscriptions.concat(data.data.subscriptions)
      }
    }
    this.setState({  subscriptions, programs })
  }

  handleDelete = async (subscription) => {
    const originalSubscriptions = this.state.subscriptions
    const subscriptions = originalSubscriptions.filter((m) => m._id !==subscription._id)
    this.setState({ subscriptions })

    try {
      await deleteSubscription(subscription._id)
    } catch (ex) {
      if (ex.response && ex.response.status ===404)
        toast.error("This subscription has already been deleted.")

      this.setState({ subscriptions: originalSubscriptions })
    }
  }

  handlePageChange = (page) => {
    this.setState({ currentPage: page })
  }

  handleProgramSelect = async (program) => {
    this.setState({
      selectedProgram: program,
      searchQuery: "",
      currentPage: 1,
    })
    if(program._id ==="") return
    const { data } = await getCategoriesByProgramId(program._id)
    const categories = [{ _id: "", name: "All Categories" }, ...data.userCategories]
    this.setState({categories})
  }

  handleCategorySelect = async (category) => {
    this.setState({
      selectedCategory: category,
      searchQuery: "",
      currentPage: 1,
    })
   
  }

  handleSearch = (query) => {
    this.setState({
      searchQuery: query,
      selectedProgram: null,
      selectedCategory: null,
      currentPage: 1,
    })
  }

  handleSort = (sortColumn) => {
    this.setState({ sortColumn })
  }

  getPagedData = () => {
    const {
      pageSize,
      currentPage,
      sortColumn,
      selectedProgram,
      selectedCategory,
      searchQuery,
      subscriptions: allSubscriptions,
    } = this.state

    let filtered = allSubscriptions
    console.log(filtered)
    if (searchQuery)
      filtered = allSubscriptions.filter((m) =>
      m.user && m.user.name.toLowerCase().startsWith(searchQuery.toLowerCase())
      )
    else if (selectedProgram && selectedProgram._id)
      filtered = allSubscriptions.filter(
        (m) => m.program._id ===selectedProgram._id
      )
    if (selectedCategory && selectedCategory._id)
      filtered = allSubscriptions.filter(
        (m) => m.userCategory ===selectedCategory._id
      )

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order])

    const subscriptions = paginate(sorted, currentPage, pageSize)

    return { totalCount: filtered.length, data: subscriptions }
  }

  render() {
    const { length: count } = this.state.subscriptions
    const { pageSize, currentPage, sortColumn, searchQuery } = this.state

    if (count ===0) return <p>There are no subscriptions in the database.</p>

    const { totalCount, data: subscriptions } = this.getPagedData()

    return (
      <div className="row">
        <div className="col-3">
          <ListGroup
            items={this.state.programs}
            selectedItem={this.state.selectedProgram}
            onItemSelect={this.handleProgramSelect}
          />
          <div className="mt-2">
          <ListGroup
            items={this.state.categories}
            selectedItem={this.state.selectedCategory}
            onItemSelect={this.handleCategorySelect}
          />
          </div>
          
        </div>
        <div className="col">
       
          <p>Mostrando {totalCount} suscripciones.</p>
          <SearchBox value={searchQuery} onChange={this.handleSearch} />
          <SubscriptionsTable
            subscriptions={subscriptions}
            sortColumn={sortColumn}
            onDelete={this.handleDelete}
            onSort={this.handleSort}
          />
          <Pagination
            itemsCount={totalCount}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageChange={this.handlePageChange}
          />
        </div>
      </div>
    )
  }
}

export default Subscriptions
