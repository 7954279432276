import React from "react";
import Joi from "joi-browser";
import Form from "../common/form";
import { getMailingsByProgramId, getMailing } from "../../services/mailingService";
import { getPrograms, getProgram } from "../../services/programService";
import { getCategoriesByProgramId, getCategory } from "../../services/categoryService";
import { getTemplates, htmlStringToObj } from "../../services/templateService";
import { toast } from "react-toastify";
import Preview from "../preview";
import SendEmailForm from "../sendEmailForm";
import { getTags } from "../../services/tagService";
import { saveMailingNumber, getMailingNumber, mailingNumberDelete } from "../../services/mailingNumberService"
import Select from "../common/select";
import { indexOf } from "lodash";

class MailingNumberForm extends Form {
  state = {
    data: {
      number: 1,
      programId: "",
      mailingId: "",
      categories: [],
      piece: ""
    },
    programs: [],
    mailings: [],
    categories: [],
    users: [],
    selectedProgram: null,
    selectedCategories: [],
    actualMailingNumberId: "",
    errors: {},
  };

  schema = {
    _id: Joi.string().allow(""),
    number: Joi.number().label("Semana"),
    mailingId: Joi.string().required().label("Envío"),
    programId: Joi.string().required().label("Programa"),
    categories: Joi.array().label("Las categorías"),
    piece: Joi.string().allow("")
  };

  async populatePrograms() {
    const { data } = await getPrograms();
    this.setState({ programs: data.programs });
  }

  async populateMailings() {
    const { data } = await getMailingsByProgramId(this.state.data.programId);
    this.setState({ mailings: data.mailings });
  }

  async populateCategories() {

    const arrayCategories = []

    for (let category of this.state.selectedCategories) {
      const { data } = await getCategory(category)
      arrayCategories.push(data.userCategory)
      this.setState({ categories: arrayCategories });
    }

    // const { data } = await getCategoriesByProgramId(this.state.data.programId);
    //  console.log(data.userCategories);
    //  this.setState({ categories: data.userCategories });
  }

  async populateMailingNumbers() {
    try {
      const mailingNumberId = this.props.id;
      if (mailingNumberId === "new") return;

      const res = await getMailingNumber(mailingNumberId);


      
      this.setState({ actualMailingNumberId: this.props.id })

      const mailingNumber = res.data.mailingNumber[0];
      this.setState({ data: this.mapToViewModel(mailingNumber) });

    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        this.props.history.replace("/not-found");
    }
  }

  async populateCategoriesWhenEdit() {
    if (this.props.id === "new") {

    } else {
      if (this.state.data.mailingId !== "") {
        const res = await getMailing(this.state.data.mailingId)

        const categories = []

        for (let category of res.data.mailing.userCategories) {
          const cat = await getCategory(category._id)
          categories.push(cat.data.userCategory._id)
          this.setState({ selectedCategories: categories })

        }
      }
    }
  }

  async componentDidMount() {
    await this.populatePrograms();
    if(!this.props.isNew)
      await this.populateMailingNumbers();
    await this.populateCategoriesWhenEdit()
  }

  async getProgram() {
    if (this.state.data.programId != undefined) {
      const { data } = await getProgram(this.state.data.programId)
      this.setState({ selectedProgram: data.program })
    }

  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.data.programId !== this.state.data.programId) {
      await this.getProgram();
      await this.populateMailings();
    }
    if (prevState.selectedCategories !== this.state.selectedCategories) {
      await this.populateCategories();
    }
    if (
      this.state.data.templateId !== "" &&
      prevState.data.templateId !== this.state.data.templateId
    ) {
      const selectedTemplate = this.state.templates.find(
        (template) => template._id === this.state.data.templateId
      );
      const { data } = this.state;
      data.html = selectedTemplate.html; //JSON.stringify(selectedTemplate.html)
      this.setState({ data });
    }
  }

  mapToViewModel(mailingNumber) {
    return {
      _id: mailingNumber._id,
      mailingId: mailingNumber.mailing._id,
      programId: mailingNumber.program._id,
      categories: mailingNumber.userCategories,
      number: mailingNumber.number
    };
  }

  doSubmit = async () => {
    try {
      // const {data} = this.state
      // const tags = data.tags.map((tag) => tag._id)
      // data.tags = tags
      // this.setState({data})
      const { data } = this.state
      const categories = data.categories.map((cat) => cat._id)
      data.categories = categories

      data.piece = this.props.pieceId

      if(this.state.actualMailingNumberId !== '' || !this.props.isNew)
        data._id = this.state.actualMailingNumberId

      this.setState({ data })
      const res = await saveMailingNumber(this.state.data);

      toast.success("El registro se actualizó con éxito")
      this.setState({ actualMailingNumberId: res.data.mailingNumber._id })
      this.refreshCategories()
    //  window.location = `/piece/${this.props.pieceId}`
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data);
      }
    }
  };

  refreshCategories = async () => {

    const newCategories = []
    for(let category of this.state.data.categories) {
         const res = await getCategory(category)
         newCategories.push(res.data.userCategory)
    }
          
     const stateData = this.state.data

     stateData.categories = newCategories
     this.setState({ data: stateData })
     
  }

  deepCopy = (node) => {
    const iterateChildren = (htmlNode) => {
      // console.log("copy this ", htmlNode)
      let copy = { ...htmlNode };
      copy._id = this.makeid();
      // console.log("into", copy)
      if (copy.hasOwnProperty("children") && copy.children.length > 0) {
        let children = [];
        for (let child of copy.children) {
          let childElement = iterateChildren(child);
          children.push(childElement);
        }
        copy.children = children;
      }

      return copy;
    };

    return iterateChildren(node);
  };

  handleChangeMultiple = ({ currentTarget: input }) => {
    const exists = this.state.data.categories.find(
      (cat) => cat._id === input.value
    );
    if (typeof exists !== "undefined") return;

    const userCategory = this.state.categories.filter(
      (cat) => cat._id === input.value
    );
    const data = { ...this.state.data };
    const originalCategories = data[input.name];

    const newCategories = originalCategories.concat(userCategory);
    data[input.name] = newCategories;
    this.setState({ data });
  };

  handleClickBadge = (categoryId) => {
    const newcategories = this.state.data.categories.filter(
      (cat) => cat._id !== categoryId
    );
    const data = { ...this.state.data };
    data.categories = newcategories;
    this.setState({ data });
  };


  makeid = () => {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < 10; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  handleDelete = async () => {

    try {
      if (this.props.id === this.props.pieceId) {
        var mailingNumberId = this.state.actualMailingNumberId
      } else {
        var mailingNumberId = this.props.id
      }

      const res = await mailingNumberDelete(mailingNumberId);
      if (res) {
        toast.success("El registro se actualizó con éxito")
        
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        toast.error("This mailing has already been deleted.");

    }
  };


  handleChange = ({ currentTarget: input }) => {

    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };
    if (input.type === "checkbox")
      input.value = input.checked
    data[input.name] = input.value;
    if (input.id == "number") {
      // console.log("numero")
    } else {
      if (input.selectedOptions[0].attributes[1]) {
        const categories = input.selectedOptions[0].attributes[1].value
        const categoriesArray = categories.split(',');
        this.setState({ selectedCategories: categoriesArray })
      }
    }

    // console.log(input.checked, input.value)
    this.setState({ data, errors });
  };


  render() {
    const pieceId = this.props.id;
    return (
      <div>
        <form onSubmit={this.handleSubmit} className="mb-3">
          {this.renderSelect("programId", "Programa", this.state.programs)}
          {this.state.selectedProgram != null && (


            <div className="form-group">

              <label>Envios</label>
              <select onChange={this.handleChange} value={this.state.data.mailingId} name="mailingId" id="mailingId" className="form-control">
                <option value="" />
                {this.state.mailings.map(mailing => (
                  <option key={mailing._id} value={mailing._id} mailing={mailing.userCategories}>
                    {mailing.name}
                  </option>
                ))}
              </select>

            </div>

          )}
          {this.state.categories.length > 0 && (
            <div className="form-group">
              <label>Categorías</label>
              <select
                name="categories"
                onChange={this.handleChangeMultiple}
                className="form-control"
                multiple
              >
                <option value="" />
                {this.state.categories.map((option) => (
                  <option key={option._id} value={option._id}>
                    {option.name}
                  </option>
                ))}
              </select>
              <div className="mt-2">
                {this.state.data.categories.map((cat) => (
                  <span
                    onClick={() => this.handleClickBadge(cat._id)}
                    className="badge badge-secondary p-2 mr-2"
                    key={cat._id}
                  >
                    {cat.name} X
                  </span>
                ))}
              </div>
            </div>
          )}
          {this.state.data.mailingId !== "" && (
            <div>
              {this.renderInput("number", "Número")}
            </div>
          )}


          <div className="mt-3">
            {this.renderButton("Guardar asignación")}
            {!this.props.isNew && <button type="button" onClick={() => this.props.onDelete(this.state.actualMailingNumberId, this.props.elementId, this.props.id)} className="btn btn-danger">Eliminar asignación</button>}
          </div>
        </form>

        {this.state.actualMailingNumberId != "" && (
          <React.Fragment> 
            <hr className="mt-5" />
            <SendEmailForm mailingNumberId={this.state.actualMailingNumberId} piece={this.props.pieceId} />
          </React.Fragment>
        )}

      </div>
    );
  }
}

export default MailingNumberForm;