import React from "react";
import Joi from "joi-browser";
import Form from "../common/form";
import { getMailingsByProgramId } from "../../services/mailingService";
import { getPrograms, getProgram } from "../../services/programService";
import { getTemplates, htmlStringToObj } from "../../services/templateService";
import { toast } from "react-toastify";
import Preview from "../preview";
import SendEmailForm from "../sendEmailFormOld";
import { getTags } from "../../services/tagService";
import { getPieces, savePiece, getPiece } from "../../services/pieceService"
import MailingNumberForm from "./mailingNumberForm"
import { getMailingNumberByPieceId, mailingNumberDelete, getMailingNumber } from "../../services/mailingNumberService"

class PieceForm extends Form {
  state = {
    data: {
      subject: "",
      templateId: "",
      html: "",
      tags: [],
    },
    form: [],
    templates: [],
    users: [],
    tags: [],
    errors: {},
    pieceId: "",
    mailingNumbers: []
  };

  schema = {
    _id: Joi.string(),
    templateId: Joi.string().allow("").label("Template"),
    subject: Joi.string().required().label("Título"),
    html: Joi.any().required().label("Html"),
    tags: Joi.array().label("Los tags"),
  };

  async populateTemplates() {
    const { data } = await getTemplates();
    this.setState({ templates: data.templates });
  }

  async populateTags() {
    const { data } = await getTags();
    this.setState({ tags: data.tags });
  }

  async getProgram() {
    if (this.state.data.programId != undefined) {
      const { data } = await getProgram(this.state.data.programId)
      this.setState({ selectedProgram: data.program })
    }

  }


  async populatePieces() {
    try {
      const pieceId = this.props.match.params.id;
      if (pieceId === "new") return;

      this.setState({ pieceId: pieceId })
      const res = await getPiece(pieceId);
      const piece = res.data.piece;

      this.setState({ data: this.mapToViewModel(piece) });


    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        this.props.history.replace("/not-found");
    }
  }

  async populateMailingNumbers() {
    if (this.props.match.params.id !== "new") {

      const res = await getMailingNumberByPieceId(this.state.pieceId)


      this.setState({ mailingNumbers: res.data.mailingNumber })

      const lenght = res.data.mailingNumber.length

      //  this.setState({ form: lenght})
    }
  }


  async componentDidMount() {
    await this.populateTemplates();
    await this.populateTags()
    await this.populatePieces();
    await this.populateMailingNumbers()
  }


  async componentDidUpdate(prevProps, prevState) {
    if (
      this.state.data.templateId !== "" &&
      prevState.data.templateId !== this.state.data.templateId
    ) {
      const selectedTemplate = this.state.templates.find(
        (template) => template._id === this.state.data.templateId
      );
      const { data } = this.state;
      data.html = selectedTemplate.html; //JSON.stringify(selectedTemplate.html)
      this.setState({ data });
    }
  }

  mapToViewModel(piece) {
    return {
      _id: piece._id,
      templateId: "",
      subject: piece.subject,
      html: piece.html,
      tags: piece.tags,
    };
  }

  doSubmit = async () => {
    try {
      // const {data} = this.state
      // const tags = data.tags.map((tag) => tag._id)
      // data.tags = tags
      // this.setState({data})

      //   const {data} = this.state
      //  data._id = this.props.match.params.id
      //   this.setState({data})

      //    console.log("DATA", data)

      const res = await savePiece(this.state.data);
      this.setState({ pieceId: res.data.piece._id })

      toast.success("El registro se actualizó con éxito")

    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data);
      }
    }
  };

  handleChangeMultiple = ({ currentTarget: input }) => {
    const exists = this.state.data.tags.find(
      (tag) => tag._id === input.value
    );
    if (typeof exists !== "undefined") return;

    const tag = this.state.tags.filter(
      (tag) => tag._id === input.value
    );
    const data = { ...this.state.data };
    const originalTags = data[input.name];

    const newTags = originalTags.concat(tag);
    data[input.name] = newTags;
    this.setState({ data });
  };

  handleClickBadge = (tagId) => {
    const newTags = this.state.data.tags.filter(
      (cat) => cat._id !== tagId
    );
    const data = { ...this.state.data };
    data.tags = newTags;
    this.setState({ data });
  };

  deepCopy = (node) => {
    const iterateChildren = (htmlNode) => {
      // console.log("copy this ", htmlNode)
      let copy = { ...htmlNode };
      copy._id = this.makeid();
      // console.log("into", copy)
      if (copy.hasOwnProperty("children") && copy.children.length > 0) {
        let children = [];
        for (let child of copy.children) {
          let childElement = iterateChildren(child);
          children.push(childElement);
        }
        copy.children = children;
      }

      return copy;
    };

    return iterateChildren(node);
  };

  handleHtmlChange = (type, id, data = {}) => {
    const { data: originalData } = this.state;
    let html = originalData.html;

    const iterateChildren = (htmlNode, position = null, parent = null) => {
      if (
        typeof htmlNode !== "undefined" &&
        htmlNode.hasOwnProperty("children") &&
        htmlNode.children.length > 0
      ) {
        for (let x = 0; x < htmlNode.children.length; x++) {
          if (
            type === "removeElement" &&
            typeof htmlNode.children[x].attrs !== "undefined" &&
            htmlNode.children[x].attrs.className === "selected"
          )
            delete htmlNode.children[x];
          else if (type === "copyTr" || type === "removeTr") {
            if (
              typeof htmlNode.children[x].attrs !== "undefined" &&
              htmlNode.children[x].attrs.className === "selected"
            ) {
              if (type === "removeTr") {
                parent.children.splice(position, 1);
              } else {
                delete htmlNode.children[x].attrs.className;
                let copy = this.deepCopy({ ...htmlNode });
                parent.children.splice(position, 0, copy);
                parent.children.join();
              }
            }

            iterateChildren(htmlNode.children[x], x, htmlNode);
          } else iterateChildren(htmlNode.children[x], x, htmlNode);
        }
      }

      if (typeof htmlNode !== "undefined" && htmlNode._id === id) {
        switch (type) {
          case "editWysiwyg":
            // console.log("value...", data.value)
            htmlNode.name = "div";
            htmlNode.attrs.className = "wysiwyg selected"
            htmlNode.content = ""
            //  console.log(htmlStringToObj(data.value))
            const obj = htmlStringToObj(data.value)
            htmlNode.children = [obj]
            // console.log("obj", htmlNode)
            break;
          case "edit":
            if (data.type === "content") htmlNode[data.type] = data.value;
            else htmlNode[data.type][data.name] = data.value;
            break;
          case "select":
            if (typeof htmlNode.attrs === "undefined") htmlNode.attrs = {};
            htmlNode.attrs.className = "selected";
            break;
          case "unselect":
            console.log(htmlNode.attrs.className)
            let pos = htmlNode.attrs.className.indexOf("selected")
            if (pos !== 0)
              pos--
            htmlNode.attrs.className = htmlNode.attrs.className.substring(0, pos)
            console.log(htmlNode.attrs.className)
            break;
          case "changeImage":
            htmlNode.attrs.src = data.src;
            break;
          case "changeHref":
            htmlNode.attrs.href = data.href;
            break;
          case "changeBgImage":
            console.log("changeBgImage")
            htmlNode.attrs.background = data.src
            htmlNode.styles.backgroundRepeat = "no-repeat"
            htmlNode.styles.backgroundSize = "cover"
            htmlNode.styles.backgroundColor = "transparent"
            // htmlNode.styles.backgroundImage = `url:(${data.src})`;
            // console.log(htmlNode)
            break;
          case "transformToAnchor":
            htmlNode.name = "a";
            if (typeof htmlNode.styles === "undefined") htmlNode.styles = {};
            htmlNode.styles["text-decoration"] = "underline";
            htmlNode.styles.color = "blue";
            htmlNode.attrs = { href: "https://" };
            break;
          case "addSpan":
            const newSpan = {
              _id: this.makeid(),
              name: "span",
              styles: {
                "font-size": htmlNode.styles["font-size"],
                color: htmlNode.styles.color,
                "font-family": htmlNode.styles["font-family"],
              },
              content: "TEXTO",
            };
            parent.children.splice(position + 1, 0, newSpan);
            parent.children.join();
            break;
          case "addNewLine":
            console.log(htmlNode);
            const br = { _id: this.makeid(), name: "br" };
            parent.children.splice(position + 1, 0, br);
            parent.children.join();
            const newLine = {
              _id: this.makeid(),
              name: "span",
              attrs: { className: "newLine" },
              styles: {
                "font-size": htmlNode.styles["font-size"],
                color: htmlNode.styles.color,
                "font-family": htmlNode.styles["font-family"],
              },
              content: "..............",
            };
            parent.children.splice(position + 2, 0, newLine);
            parent.children.join();
            break;
          default:
            break;
        }
      }

      return htmlNode;
    };

    originalData.html = iterateChildren(html);

    this.setState({ data: originalData });
  };

  makeid = () => {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < 10; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };


  addOne = () => {
    const form = this.state.form

    const id = this.makeid(15)

    form.push(id)

    this.setState({ form })
  }

  handleDelete = async (mailingNumberForm, elementId, id) => {

    if (elementId !== "") {
      const form = this.state.form
      const index = form.indexOf(elementId)
      const nextIndex = index + 1
      form.splice(index, nextIndex)
    }

    if (id === "new") {
      var mailingNumberId = mailingNumberForm
    } else {
      var mailingNumberId = id
    }




    const mailingNumbersForm = this.state.mailingNumbers

    var filtered = mailingNumbersForm.filter(function (el) { return el._id != mailingNumberId });

    const res = await mailingNumberDelete(mailingNumberId);
    if (res) {
      toast.success("El registro se actualizó con éxito")
      this.setState({ mailingNumbers: filtered })
    }
  }

  render() {
    const pieceId = this.props.match.params.id;
    return (
      <div>
        {pieceId === "new" && <h2>Crear Pieza</h2>}
        {pieceId !== "new" && <h2>Editar Pieza</h2>}
        <form onSubmit={this.handleSubmit} className="mb-3">
          {this.renderInput("subject", "Asunto")}
          {this.renderSelect("templateId", "Template", this.state.templates)}
          {this.state.tags.length > 0 && (
            <div className="form-group">
              <label>Tags</label>
              <select
                name="tags"
                onChange={this.handleChangeMultiple}
                className="form-control"
                multiple
              >
                <option value="" />
                {this.state.tags.map((option) => (
                  <option key={option._id} value={option._id}>
                    {option.name}
                  </option>
                ))}
              </select>
              <div className="mt-2">
                {this.state.data.tags.map((tag) => (
                  <p
                    onClick={() => this.handleClickBadge(tag._id)}
                    className="badge badge-secondary p-2 mr-2"
                    key={`badge-${tag._id}`}
                  >
                    {tag.name} X
                  </p>
                ))}
              </div>
            </div>
          )}

          <Preview
            handleHtmlChange={this.handleHtmlChange}
            editable={true}
            html={this.state.data.html}
            selectedProgram={this.state.selectedProgram}
          ></Preview>

          <div style={{ display: "none" }}>
            {this.renderTextarea("html", "Html")}
          </div>

          <div className="mt-3">{this.renderButton("Guardar")}</div>
        </form>
        {this.state.pieceId != "" && (
          <button className="btn btn-warning" onClick={this.addOne}>Asignar a:</button>
        )}
        {this.state.form.map(element => <MailingNumberForm key={element} elementId={element} onDelete={this.handleDelete} pieceId={this.state.pieceId} id={element} isNew={true} />)}
        {this.state.mailingNumbers.map(mailingNumber => (<MailingNumberForm onDelete={this.handleDelete} pieceId={this.state.pieceId} id={mailingNumber._id} isNew={false} />))}

      </div>
    );
  }
}

export default PieceForm;
