// import ReactDOM from "react-dom";
import React, { PureComponent } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

// import './App.css';

class ImageUpload extends PureComponent {
  state = {
    src: null,
    croppedImageUrl: null,
    crop: {
      unit: "%",
      width: 30,
      aspect: 16 / 9,
    },
  };

  componentDidMount(){
      const { crop } = this.state
      crop.aspect = parseInt(this.props.aspect.x) / parseInt(this.props.aspect.y)
    //   console.log(this.props.aspectX, parseInt(this.props.aspectX), this.props.aspectY, parseInt(this.props.aspectY), crop.aspect)
       this.setState({crop})
  }

//    componentDidUpdate(prevProps, prevState) {

//     if(prevState.croppedImageUrl !== this.state.croppedImageUrl){
//         this.props.changeImage(this.state.src);
//         // this.props.changeImage(this.state.croppedImageUrl)
//     }
//     // if (prevState.src !== this.state.src) {
//     //     this.props.changeImage(this.state.src);
//     //   }
//   }

  onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        this.setState({ src: reader.result })
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  // If you setState the crop in here you should return false.
  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.jpeg"
      );

      this.setState({ croppedImageUrl });
      //   this.props.changeImage(this.state.src)
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      // crop.x,
      // crop.y,
      // crop.width,
      // crop.height,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
        //   console.error("Canvas is empty");
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, "image/jpeg");

      const newBase64 = canvas.toDataURL("image/jpeg")
    //   console.log(newBase64)
        this.props.changeImage(newBase64)

    });
  }

  render() {
    const { crop,  src } = this.state;

    return (
      <div className="App">
        <div>
          <input type="file" accept="image/*" onChange={this.onSelectFile} />
        </div>
        {src && (
          <ReactCrop
            src={src}
            crop={crop}
            ruleOfThirds
            onImageLoaded={this.onImageLoaded}
            onComplete={this.onCropComplete}
            onChange={this.onCropChange}
          />
        )}
        {/* {croppedImageUrl && (
              <img alt="Crop" style={{ maxWidth: '100%' }} src={croppedImageUrl} />
            )} */}
      </div>
    );
  }
}

export default ImageUpload;
