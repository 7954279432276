import React from "react";
import Joi from "joi-browser";
import Form from "../common/form";
import { getAdmin, saveAdmin } from "../../services/adminService";
import { toast } from "react-toastify";

class AdminForm extends Form {

  state = {
    data: {
      name: "",
      lastname: "",
      email: "",
      password: ""
    },
    errors: {}
  };

  schema = {
    _id: Joi.string(),
    name: Joi.string()
      .required()
      .label("Nombre"),
    lastname: Joi.string()
      .required()
      .label("Apellido"),
    email: Joi.string()
      .required()
      .email()
      .label("Email"),
    password: Joi.string()
      // .required()
      .label("Password"),
  };


  async populateAdmin() {
    try {
      const adminId = this.props.match.params.id;
      if (adminId ==="new") return;

      const {data} = await getAdmin(adminId);
      console.log(data)
      this.setState({ data: this.mapToViewModel(data.admin) });
    } catch (ex) {
      if (ex.response && ex.response.status ===404)
        this.props.history.replace("/not-found");
    }
  }

  async componentDidMount() {
    await this.populateAdmin();
  }

  mapToViewModel(admin) {
    return {
      _id: admin._id,
      name: admin.name,
      lastname: admin.lastname,
      email: admin.email
    };
  }

  doSubmit = async () => {
    try {
       await saveAdmin(this.state.data);
        this.props.history.push("/admins");
      } catch (ex) {
        if (ex.response && ex.response.status ===400) {
          toast.error(ex.response.data);
        //   const errors = { ...this.state.errors }
        //   errors.email = ex.response.data
        //   this.setState({ errors })
        }
      }
  };

  render() {
    const adminId = this.props.match.params.id
    return (
      <div>
        {adminId ==='new' && <h2>Crear Admin</h2>}
        {adminId !=='new' && <h2>Editar Admin</h2>}
        <form onSubmit={this.handleSubmit}>
          {this.renderInput("name", "Nombre")}
          {this.renderInput("lastname", "Apellido")}
          {this.renderInput("email", "Email", "email")}
          {adminId ==='new' && 
            this.renderInput("password", "Password", "password")
          }
          
          {this.renderButton("Save")}
        </form>
      </div>
    );
  }
}

export default AdminForm;
