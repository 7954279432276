import http from "./httpService";
import { apiUrl } from "../config.json";

const apiEndpoint = apiUrl + "/tags";


function tagUrl(id) {
    return `${apiEndpoint}/${id}`;
  }

export function getTags() {
  return http.get(`${apiEndpoint}`);
}

export function getTag(id) {
  return http.get(tagUrl(id));
}

export function saveTag(tag) {
  if (tag._id) {
    const body = { ...tag };
    delete body._id;
    return http.put(tagUrl(tag._id), body);
  }

  return http.post(`${apiEndpoint}`, tag);
}

export function deleteTag(id) {
  return http.delete(tagUrl(id));
}
